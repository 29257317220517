import { ClickAwayListener, Popper as MaterialPopper } from '@mui/material';
import { ReactNode, useState } from 'react';

import style from './popper.module.scss';

type Props = {
  children({ onClose }: { onClose: () => void }): ReactNode;
  trigger: ReactNode;
  handleOnClose?: () => void;
  onOpen?: () => void;
};

const Popper = ({ children, trigger, handleOnClose, onOpen }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onClose = () => {
    handleOnClose?.();
    setAnchorEl(null);
  };

  const onClickAway = (e: MouseEvent | TouchEvent) => {
    // for some reason when clicking on Dropdown component inside the popper
    // it register as a click on body element, hence this check
    if (e.target instanceof HTMLBodyElement) {
      return;
    }
    onClose();
  };

  return (
    <>
      <div
        onClick={(e) => {
          onOpen?.();
          setAnchorEl(e.currentTarget);
        }}
      >
        {trigger}
      </div>
      <MaterialPopper anchorEl={anchorEl} open={Boolean(anchorEl)} style={{ zIndex: 1000 }}>
        <ClickAwayListener onClickAway={onClickAway}>
          <div className={style.popper}>{children({ onClose })}</div>
        </ClickAwayListener>
      </MaterialPopper>
    </>
  );
};

export default Popper;
