import { AnimatePresence, motion } from 'framer-motion';
import { Dropdown, Stack } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setAllClientViewSections,
  setAllDayViewSections,
  setStatus,
  Status,
  Todo
} from 'store/myTodos/myTodosSlice';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';

import style from './filters.module.scss';
import { SearchInput } from './SearchInput';

const todoStatuses = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'COMPLETED', name: 'Completed' },
  { id: 'ABANDONED', name: 'Abandoned' }
];

const haveADraft = ({ todo, userId }: { todo: Todo; userId: any }) =>
  Boolean(todo.draft) &&
  todo.draft?.userId === userId &&
  todo.draft?.creationContext === 'MY_TODOS';

const Filters = () => {
  const { userId } = getLsUserRoleObject();
  const dispatch = useAppDispatch();
  const showSearch = useAppSelector((state) => state.myTodos.search.show);

  const view = useAppSelector((state) => state.myTodos.view);
  const todoWithDraftExists = useAppSelector((state) =>
    state.myTodos.todos.data.some((todo) => haveADraft({ todo, userId }) || todo.isAutosaving)
  );
  const status = useAppSelector((state) => state.myTodos.filters.status);

  const areAnyDayViewSectionsExpanded = useAppSelector((state) =>
    Object.keys(state.myTodos.dayViewExpandedSections).some(
      (key) => state.myTodos.dayViewExpandedSections[key]
    )
  );
  const areAnyClientViewSectionsExpanded = useAppSelector((state) =>
    Object.keys(state.myTodos.clientViewExpandedSections).some(
      (key) => state.myTodos.clientViewExpandedSections[key]
    )
  );

  return (
    <motion.div>
      <Stack
        height="36px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <AnimatePresence initial={false} mode="popLayout">
          {!showSearch && (
            <motion.div
              style={{ flexGrow: 1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
              exit={{ opacity: 0 }}
            >
              <Dropdown
                data-test-id="roadmap-statuses-dropdown"
                value={status}
                data={todoStatuses}
                onChange={(e) => {
                  if (Array.isArray(e.target.value)) {
                    dispatch(setStatus(e.target.value as Status[]));
                  }
                }}
                rounded
                size="medium"
                label="Status"
                multiSelect
                className={status.length > 0 ? style.filterSelected : ''}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <SearchInput />
        {!showSearch && (
          <motion.div
            layoutId="icon"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <button
              className={style.actionButton}
              onClick={() => {
                if (view === 'dayView') {
                  dispatch(setAllDayViewSections(areAnyDayViewSectionsExpanded ? false : true));
                }
                if (view === 'clientView') {
                  dispatch(
                    setAllClientViewSections(areAnyClientViewSectionsExpanded ? false : true)
                  );
                }
              }}
              disabled={todoWithDraftExists}
            >
              {view === 'dayView' ? (
                areAnyDayViewSectionsExpanded ? (
                  <span className="material-icons">unfold_less</span>
                ) : (
                  <span className="material-icons">unfold_more</span>
                )
              ) : null}
              {view === 'clientView' ? (
                areAnyClientViewSectionsExpanded ? (
                  <span className="material-icons">unfold_less</span>
                ) : (
                  <span className="material-icons">unfold_more</span>
                )
              ) : null}
            </button>
          </motion.div>
        )}
      </Stack>
    </motion.div>
  );
};

export default Filters;
