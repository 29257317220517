import dayjs from 'dayjs';
import { convertLocalToUTC } from 'utils/dateHelpers/dateHelpers';

export const getHasDifferentEndDate = (
  startDateTime: string | undefined | null,
  endDateTime: string | undefined | null
) => {
  if (!endDateTime) {
    return false;
  }

  if (!endDateTime && !startDateTime) {
    return false;
  }

  return !dayjs(startDateTime).isSame(endDateTime, 'date');
};

export const mapDates = ({
  date,
  endDate,
  startTime,
  endTime,
  hasDifferentEndDate
}: {
  date: string | null | undefined;
  endDate: string | null | undefined;
  startTime: string | null | undefined;
  endTime: string | null | undefined;
  hasDifferentEndDate: boolean;
}) => {
  if (!startTime || !endTime) {
    if (!date) {
      return { date: null, startDateTime: null, endDateTime: null };
    }
    return { date, startDateTime: null, endDateTime: null };
  }

  return {
    startDateTime: convertLocalToUTC(date ?? '', startTime),
    endDateTime: convertLocalToUTC((hasDifferentEndDate ? endDate : date) ?? '', endTime),
    date: null
  };
};
