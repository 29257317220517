import { InputField, LabelComponent, Stack } from 'components';
import { useFormikContext } from 'formik';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';

type Props = {
  disabled?: boolean;
};

const LocationForm = ({ disabled }: Props) => {
  const { values, handleChange, handleBlur } = useFormikContext<AddTodoFormValues>();

  return (
    <Stack width="220px">
      <LabelComponent text="Location" />
      <InputField
        disabled={disabled}
        name="location"
        placeholder="Enter location"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.location}
      />
    </Stack>
  );
};

export default LocationForm;
