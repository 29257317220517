import clsx from 'clsx';
import { CSSProperties } from 'react';

import abandonButtonStyle from './abandonButton.module.scss';

const AbandonButton = ({ style = {} }: { style?: CSSProperties }) => {
  return (
    <span style={{ ...style }} className={clsx('material-icons', abandonButtonStyle.icon)}>
      delete
    </span>
  );
};

export default AbandonButton;
