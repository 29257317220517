import { StandardCheckbox } from 'components';
import { ComponentProps } from 'react';

import style from './checkbox.module.scss';

const Checkbox = (props: ComponentProps<typeof StandardCheckbox>) => {
  return <StandardCheckbox {...props} customClass={style.checkbox} />;
};

export default Checkbox;
