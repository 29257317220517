import { useLayoutEffect } from 'react';

export function useLockBodyScroll({ lock }: { lock: boolean }) {
  useLayoutEffect(() => {
    // Save initial body padding to prevent layout shift
    const originalPadding = window.getComputedStyle(document.body).paddingRight;
    const hasScroll = window.innerWidth > document.documentElement.clientWidth;

    if (lock) {
      // Only add padding if there's a scrollbar to prevent layout shift
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      if (hasScroll) {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      }

      // Use position: fixed instead of overflow: hidden
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = '100%';
    } else {
      // Restore original position and scroll
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.paddingRight = originalPadding;

      if (scrollY) {
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    }

    return () => {
      // Clean up
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.paddingRight = originalPadding;
    };
  }, [lock]);
}
