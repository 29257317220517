import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDayListSectionTitle } from 'modules/myTodos/utils/sectionUtils';
import {
  getLsUserRoleObject,
  getMyTodosFilters,
  setMyTodosFilters
} from 'modules/networkTools/localStorageTokens';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  deleteNewTodoDraft,
  deleteSearchQueryDraft,
  deleteTodoDraft,
  getGoals,
  getNewTodoDraft,
  getSearchQueryDraft,
  getTodos,
  patchUpdateTodoStatus,
  postCreateTodo,
  putNewTodoDraft,
  putSearchQueryDraft,
  putUpdateTodo,
  putUpdateTodoDraft,
  searchClients,
  searchTodos
} from './myTodosActions';
import { isActive, isInactive, isInFuture, isInPast } from './selectors';

export type CommonTodo = {
  updatedAt?: string;
  createdAt?: string;
  statusUpdatedAt?: string;
  id?: number;
  goalId: number | null;
  title: string | null;
  status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED';
  date?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  location: string | null;
  note: string | null;
  color: string;
  orderNum?: number | null;
  client: {
    name: string;
    id: number;
  } | null;
};

type DraftTodo = CommonTodo & {
  creationContext: 'ROADMAPS' | 'MY_TODOS';
  userId: number;
  userName: string;
};

export type Todo = CommonTodo & {
  draft: DraftTodo | null;
  newlyCreated?: boolean;
  goalStatus: 'ACTIVE' | 'COMPLETED' | 'ABANDONED' | null;
  goals?: Goal[];
  isAutosaving?: boolean;
};

type View = 'dayView' | 'clientView';

export type Status = 'ACTIVE' | 'COMPLETED' | 'ABANDONED';

type Filters = {
  status: Status[];
};

type Search = {
  show: boolean;
  query: string;
  data: Todo[] | null;
};

type Client = {
  id: number;
  name: string;
};

type Goal = {
  id: number;
  title: string;
};

type InitialState = {
  hasOpenedPopper?: boolean;
  loading: boolean;
  search: Search;
  newTodoDraft: Todo['draft'] | null;
  clients: {
    loading: boolean;
    data: Client[];
  };
  goals: {
    loading: boolean;
    data: Goal[];
  };
  filters: Filters;
  view: View;
  todos: {
    data: Todo[];
    total: number;
    page: number;
    size: number;
  };
  dayViewExpandedSections: Record<string, boolean>;
  clientViewExpandedSections: Record<string, boolean>;
};

const initialState: InitialState = {
  loading: false,
  search: {
    show: false,
    query: '',
    data: null
  },
  clients: {
    loading: false,
    data: []
  },
  goals: {
    loading: false,
    data: []
  },
  newTodoDraft: null,
  filters: {
    status: getMyTodosFilters().status ?? ['ACTIVE']
  },
  dayViewExpandedSections: getMyTodosFilters().dayViewExpandedSections ?? {
    Overdue: true
  },
  clientViewExpandedSections: getMyTodosFilters().clientViewExpandedSections ?? {
    'No client': true
  },

  view: getMyTodosFilters().view ?? 'dayView',
  todos: {
    data: [],
    total: 0,
    page: 0,
    size: 50
  }
};

export const myTodosSlice = createSlice({
  name: 'myTodos',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<View>) => {
      state.view = action.payload;

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    toggleDayViewSection: (
      state,
      action: PayloadAction<{
        section: string;
        expanded: boolean;
      }>
    ) => {
      state.dayViewExpandedSections[action.payload.section] = action.payload.expanded;

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    toggleClientViewSection: (
      state,
      action: PayloadAction<{
        section: string;
        expanded: boolean;
      }>
    ) => {
      state.clientViewExpandedSections[action.payload.section] = action.payload.expanded;

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    setAllDayViewSections: (state, action: PayloadAction<boolean>) => {
      Object.keys(state.dayViewExpandedSections).forEach((key) => {
        state.dayViewExpandedSections[key] = action.payload;
      });

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    setAllClientViewSections: (state, action: PayloadAction<boolean>) => {
      Object.keys(state.clientViewExpandedSections).forEach((key) => {
        state.clientViewExpandedSections[key] = action.payload;
      });

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    setStatus: (state, action: PayloadAction<Status[]>) => {
      state.filters.status = action.payload;

      setMyTodosFilters({
        status: state.filters.status,
        view: state.view,
        dayViewExpandedSections: state.dayViewExpandedSections,
        clientViewExpandedSections: state.clientViewExpandedSections
      });
    },
    clearNewTodoDraft: (state) => {
      state.newTodoDraft = null;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.search.query = action.payload;
    },
    openSearch: (state) => {
      state.search.show = true;
    },
    closeSearch: (state) => {
      state.search.show = false;
      state.search.query = '';
    },
    clearGoals: (state, action: PayloadAction<{ todoId?: number } | undefined>) => {
      if (action.payload?.todoId) {
        const todo = state.todos.data.find((t) => t.id === action.payload?.todoId);
        if (todo) {
          todo.goals = [];
        }
      } else {
        state.goals.data = [];
      }
    },
    clearClients: (state) => {
      state.clients.data = [];
    },
    clearNewlyCreated: (state, action: PayloadAction<number>) => {
      const todo = state.todos.data.find((t) => t.id === action.payload);
      if (todo) {
        todo.newlyCreated = false;
      }
    },
    clearSearchData: (state) => {
      state.search.data = null;
    },
    setHasOpenedPopper: (state, action: PayloadAction<{ opened: boolean }>) => {
      state.hasOpenedPopper = action.payload.opened;
    },
    updateTodo: (state, action: PayloadAction<Partial<Todo>>) => {
      const index = state.todos.data.findIndex((todo) => todo.id === action.payload.id);
      if (index !== -1) {
        state.todos.data[index] = {
          ...state.todos.data[index],
          ...action.payload
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCreateTodo.fulfilled, (state, { meta, payload }) => {
        state.newTodoDraft = null;

        let smallestOrderNum: undefined | number;
        if (meta.arg.clientId) {
          // find all todos that has the same client id and the smallest orderNum
          const todosWithSameClient = state.todos.data.filter(
            (todo) => todo.client?.id === meta.arg.clientId && todo.status === 'ACTIVE'
          );

          if (todosWithSameClient.length === 0) {
            smallestOrderNum = 2;
          } else {
            smallestOrderNum = Math.min(...todosWithSameClient.map((todo) => todo.orderNum ?? 0));
          }
        }

        state.todos.data.push({
          goalStatus: 'ACTIVE',
          client: payload.client,
          id: payload.id,
          goalId: meta.arg.goalId,
          title: meta.arg.title,
          status: 'ACTIVE',
          date: meta.arg.date,
          startDateTime: meta.arg.startDateTime,
          endDateTime: meta.arg.endDateTime,
          location: meta.arg.location,
          note: meta.arg.note,
          color: meta.arg.color,
          draft: null,
          orderNum: smallestOrderNum != null ? smallestOrderNum - 1 : undefined,
          newlyCreated: true,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          statusUpdatedAt: new Date().toISOString()
        });
        notifyUserSuccess('New To-do created');
      })
      .addCase(postCreateTodo.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putNewTodoDraft.fulfilled, (state, { payload, meta }) => {
        state.newTodoDraft = {
          id: payload.id,
          client: payload.client,
          title: meta.arg.title,
          status: 'ACTIVE',
          startDateTime: meta.arg.startDateTime,
          endDateTime: meta.arg.endDateTime,
          date: meta.arg.date,
          location: meta.arg.location,
          note: meta.arg.note,
          color: meta.arg.color,
          creationContext: meta.arg.creationContext,
          userId: getLsUserRoleObject().userId,
          goalId: meta.arg.goalId,
          userName: getLsUserRoleObject().name
        };
      })
      .addCase(putNewTodoDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getNewTodoDraft.fulfilled, (state, { payload }) => {
        if (payload == null || payload === '') {
          state.newTodoDraft = null;
          return;
        }
        state.newTodoDraft = payload;
      })
      .addCase(getTodos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTodos.fulfilled, (state, { payload }) => {
        const { userId } = getLsUserRoleObject();

        payload.content.forEach((todo: Todo) => {
          const hasADraft =
            Boolean(todo.draft) &&
            todo.draft?.userId === userId &&
            todo.draft?.creationContext === 'MY_TODOS';

          if (hasADraft) {
            if (isInactive(todo) && isInPast(todo) && (todo.date || todo.startDateTime)) {
              state.dayViewExpandedSections[
                getDayListSectionTitle(todo.date ?? todo.startDateTime ?? '')
              ] = true;
            }
            if (!todo.date && !todo.startDateTime) {
              state.dayViewExpandedSections['No date'] = true;
            }
            if (isActive(todo) && isInPast(todo)) {
              state.dayViewExpandedSections['Overdue'] = true;
            }
            if (isInFuture(todo)) {
              state.dayViewExpandedSections['Future'] = true;
            }
            if (todo.client) {
              state.clientViewExpandedSections[todo.client.name] = true;
            } else {
              state.clientViewExpandedSections['No client'] = true;
            }
          }
        });

        state.todos.data = payload.content;
        state.loading = false;
      })
      .addCase(getTodos.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteNewTodoDraft.fulfilled, (state) => {
        state.newTodoDraft = null;
      })
      .addCase(deleteNewTodoDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(searchClients.pending, (state) => {
        state.clients.loading = true;
      })
      .addCase(searchClients.fulfilled, (state, { payload }) => {
        state.clients.data = payload.content;
        state.clients.loading = false;
      })
      .addCase(getGoals.fulfilled, (state, { payload, meta }) => {
        const goals = payload.filter((goal: Goal) => Boolean(goal.id));

        if (meta.arg.todoId) {
          const todo = state.todos.data.find((t) => t.id === meta.arg.todoId);
          if (todo) {
            todo.goals = goals;
          }
        } else {
          state.goals.data = goals;
        }
      })
      .addCase(getGoals.rejected, (state, { payload }: any) => {
        state.goals.loading = false;
        notifyUserError(payload);
      })
      .addCase(getSearchQueryDraft.fulfilled, (state, { payload }) => {
        if (payload?.searchQuery !== null && payload?.searchQuery !== '') {
          state.search.show = true;
          state.search.query = payload.searchQuery;
        }
      })
      .addCase(deleteSearchQueryDraft.fulfilled, (state) => {
        state.search.show = false;
        state.search.query = '';
      })
      .addCase(deleteSearchQueryDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putSearchQueryDraft.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(searchTodos.fulfilled, (state, { payload }) => {
        state.search.data = payload;
      })
      .addCase(putUpdateTodoDraft.pending, (state, { meta }) => {
        const todoIndex = state.todos.data.findIndex((todo) => todo.id === meta.arg.id);
        if (todoIndex !== -1) {
          state.todos.data[todoIndex].isAutosaving = true;
        }
      })
      .addCase(putUpdateTodoDraft.fulfilled, (state, { payload, meta }) => {
        const searchIndex = state.search.data?.findIndex((todo) => todo.draft?.id === meta.arg.id);
        if (searchIndex && state.search.data?.[searchIndex]) {
          state.search.data[searchIndex].draft = payload;
        }

        state.todos.data = state.todos.data.map((todo) => {
          if (todo.id === meta.arg.id) {
            return { ...todo, draft: payload, isAutosaving: false };
          }
          return todo;
        });
      })
      .addCase(deleteTodoDraft.pending, (state, { meta }) => {
        const index = state.todos.data.findIndex((todo) => {
          return todo?.id === meta.arg.id;
        });

        const searchIndex = state.search.data?.findIndex((todo) => todo.draft?.id === meta.arg.id);

        if (state.todos.data[index]) {
          state.todos.data[index].draft = null;
        }
        if (searchIndex && state.search.data?.[searchIndex]) {
          state.search.data[searchIndex].draft = null;
        }
      })
      .addCase(patchUpdateTodoStatus.pending, (state, { meta }) => {
        if (meta.arg.status === 'COMPLETED') {
          notifyUserSuccess('To-do was completed.');
        }

        if (meta.arg.status === 'ABANDONED') {
          notifyUserSuccess('To-do was abandoned.');
        }

        if (meta.arg.status === 'ACTIVE') {
          notifyUserSuccess('To-do was reactivated.');
        }

        if (state.search.data) {
          state.search.data = state.search.data.map((todo) => {
            if (todo.id === meta.arg.id) {
              return { ...todo, status: meta.arg.status };
            }
            return todo;
          });
        }

        state.todos.data = state.todos.data.map((todo) => {
          if (todo.id === meta.arg.id) {
            if (todo.client && meta.arg.status === 'ACTIVE') {
              const todosWithSameClient = state.todos.data.filter(
                (t) => t.client?.id === todo.client?.id && t.status === 'ACTIVE'
              );

              const smallestOrderNum =
                todosWithSameClient.length > 0
                  ? Math.min(...todosWithSameClient.map((t) => t.orderNum ?? 0))
                  : 2;

              return {
                ...todo,
                status: meta.arg.status,
                orderNum: smallestOrderNum - 1,
                updatedAt: new Date().toISOString(),
                statusUpdatedAt: new Date().toISOString()
              };
            }
            return {
              ...todo,
              status: meta.arg.status,
              orderNum: null,
              updatedAt: new Date().toISOString(),
              statusUpdatedAt: new Date().toISOString()
            };
          }
          return todo;
        });
      })
      .addCase(patchUpdateTodoStatus.fulfilled, (state, { payload }) => {
        state.todos.data = state.todos.data.map((todo) => {
          if (todo.id === payload.id) {
            return {
              ...todo,
              status: payload.status,
              statusUpdatedAt: payload.statusUpdatedAt,
              updatedAt: payload.updatedAt
            };
          }
          return todo;
        });
      })
      .addCase(putUpdateTodo.fulfilled, (state, { payload, meta }) => {
        const searchListTodoIndex = state.search.data?.findIndex(
          (todo) => todo.draft?.id === payload.id
        );
        if (searchListTodoIndex && state.search.data?.[searchListTodoIndex]) {
          state.search.data[searchListTodoIndex] = { ...payload, note: meta.arg.note, draft: null };
        }

        const todoListIndex = state.todos.data.findIndex((todo) => todo.id === payload.id);

        if (state.todos.data[todoListIndex]) {
          state.todos.data[todoListIndex] = { ...payload, note: meta.arg.note, draft: null };
        }
      });
  }
});

export const {
  setView,
  toggleClientViewSection,
  toggleDayViewSection,
  setAllDayViewSections,
  setAllClientViewSections,
  setStatus,
  clearNewTodoDraft,
  setSearchQuery,
  openSearch,
  closeSearch,
  clearGoals,
  clearClients,
  clearNewlyCreated,
  clearSearchData,
  setHasOpenedPopper,
  updateTodo
} = myTodosSlice.actions;

export default myTodosSlice.reducer;
