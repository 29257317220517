import clsx from 'clsx';
import { FocusEventHandler, forwardRef } from 'react';

import style from './textAreaInputField.module.scss';

interface TextAreaInputFieldProps {
  ref?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  rowsNumber?: number;
  onBlur: FocusEventHandler<HTMLTextAreaElement>;
  onChange: FocusEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  enableCounter?: boolean;
  classnamesProps?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
}

const TextAreaInputField = forwardRef<HTMLTextAreaElement, TextAreaInputFieldProps>(
  (
    {
      id,
      name,
      placeholder,
      value = '',
      rowsNumber = 7,
      onBlur,
      onChange,
      maxLength = 500,
      enableCounter = false,
      classnamesProps,
      disabled,
      autoFocus = false,
      autoComplete = 'no-autocomplete'
    },
    ref
  ) => {
    return (
      <div className={clsx(style.inputContainer)} data-disabled={disabled}>
        {enableCounter && (
          <div className={clsx(style.textAreaCounter)} data-error={value.length >= maxLength}>
            {value.length || 0}/{maxLength}
          </div>
        )}
        <textarea
          className={clsx(classnamesProps)}
          maxLength={10000}
          ref={ref}
          data-hasValue={value !== '' && value != null}
          id={id}
          name={name}
          rows={rowsNumber}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        ></textarea>
      </div>
    );
  }
);

export default TextAreaInputField;
