import clsx from 'clsx';
import { Stack } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef } from 'react';

import style from './section.module.scss';

type Props = {
  expanded?: boolean;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const Section = forwardRef<HTMLDivElement, Props>(
  ({ expanded, title, children, disabled, onClick }: Props, ref) => {
    return (
      <motion.div layout>
        <Stack width="100%" ref={ref} id={title} bgcolor="white">
          <motion.button
            layout
            onClick={onClick}
            disabled={disabled}
            className={style.expandButton}
          >
            <span
              className={clsx('material-icons', style.icon)}
              data-expanded={expanded}
              data-disabled={disabled}
            >
              keyboard_arrow_down
            </span>
            <p className={style.date}>{title}</p>
          </motion.button>
          <AnimatePresence initial={false}>
            {expanded && (
              <motion.div
                layout
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2, bounce: 0, type: 'spring' }}
                style={{ overflowY: 'hidden', overflowX: 'hidden' }}
              >
                <Stack display="flex" flexDirection="column" gap="2px">
                  <AnimatePresence>{children}</AnimatePresence>
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
      </motion.div>
    );
  }
);

export default Section;
