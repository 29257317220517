import clsx from 'clsx';
import { motion } from 'framer-motion';
import debounce from 'lodash.debounce';
import { ChangeEvent, forwardRef, useCallback, useRef } from 'react';
import { flushSync } from 'react-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteSearchQueryDraft, putSearchQueryDraft } from 'store/myTodos/myTodosActions';
import { closeSearch, setSearchQuery, openSearch } from 'store/myTodos/myTodosSlice';

import style from './searchInput.module.scss';

const SearchInput = forwardRef<HTMLInputElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const { query, show: showSearch } = useAppSelector((state) => state.myTodos.search);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const autosaveSearchQuery = async (searchQuery: string) => {
    dispatch(putSearchQueryDraft({ searchQuery }));
  };

  const debounceSearch = useCallback(debounce(autosaveSearchQuery, 400), []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value));

    debounceSearch(e.target.value);
  };

  const onCloseSearch = () => {
    dispatch(deleteSearchQueryDraft());
    dispatch(closeSearch());
  };

  return (
    <motion.div
      data-search-opened={showSearch}
      className={style.searchWrapper}
      data-has-value={query !== ''}
    >
      <motion.button
        layout
        layoutId="searchIcon"
        disabled={showSearch}
        className={style.actionButton}
        onClick={() => {
          flushSync(() => {
            dispatch(openSearch());
          });
          searchInputRef.current?.focus();
        }}
      >
        <span className="material-icons">search</span>
      </motion.button>

      {showSearch && (
        <motion.input
          value={query}
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.4 } }}
          ref={ref}
          style={{ flexGrow: 1 }}
          className={style.todoInput}
          placeholder="Search"
          onChange={onChange}
        />
      )}
      {showSearch && (
        <motion.button
          layoutId="icon"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onCloseSearch}
          className={style.exitSearchIcon}
        >
          <span className={clsx('material-icons', style.exitSearchIcon)}>highlight_off</span>
        </motion.button>
      )}
    </motion.div>
  );
});

export default SearchInput;
