import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleDayViewSection } from 'store/myTodos/myTodosSlice';

import { Section } from '../components/Section';

type Props = {
  expanded?: boolean;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
};

const DayListSection = ({ expanded: _expanded = false, title, children, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector(
    (state) => state.myTodos.dayViewExpandedSections[title] ?? _expanded
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(toggleDayViewSection({ section: title, expanded: isExpanded }));
  }, [title, isExpanded]);

  return (
    <Section
      ref={ref}
      title={title}
      expanded={isExpanded}
      disabled={disabled}
      onClick={() => dispatch(toggleDayViewSection({ section: title, expanded: !isExpanded }))}
    >
      {children}
    </Section>
  );
};

export default DayListSection;
