import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getLaunchDarklyContextKey } from 'modules/networkTools/localStorageTokens';
import { useEffect } from 'react';

const useLaunchDarklyContextUpdate = () => {
  const ldClient = useLDClient();

  const reloadLdContext = async () => {
    const ldContext = getLaunchDarklyContextKey() || '{}';
    if (ldContext !== '{}') {
      await ldClient?.identify(JSON.parse(ldContext));
    }
  };
  useEffect(() => {
    reloadLdContext();
  }, []);
};

export default useLaunchDarklyContextUpdate;
