import clsx from 'clsx';
import { Stack } from 'components';
import { motion, useAnimate } from 'framer-motion';

import style from './actions.module.scss';
import { Popper } from './popper';

type Action = {
  name: string;
  icon: string | JSX.Element;
  popover?: JSX.Element;
  show?: boolean;
  hasValues?: boolean;
  hasError?: boolean;
  disabled?: boolean;
};

type Props = {
  onActionClick?: ({ type }: { type: string }) => void;
  actions: Action[];
  color: string;
  onOpen?: ({ name }: { name: string }) => void;
  onClose?: ({ name }: { name: string }) => void;
  disableLayout?: boolean;
};

const Actions = ({ onActionClick, actions, color, onOpen, onClose, disableLayout }: Props) => {
  const [scope, animate] = useAnimate();

  const handleOnClose = ({ hasError, name }: { hasError?: boolean; name: string }) => {
    if (!hasError) return;

    animate(
      `[data-name="${name}"]`,
      {
        x: [-3, 3, -3, 3, 0]
      },
      { duration: 0.2, ease: 'easeIn' }
    );
  };

  return (
    <Stack ref={scope} display="flex" alignItems="start" flexDirection="row" gap="10px" mt="12px">
      {actions.map(
        (action, index) =>
          action.show &&
          (action.popover ? (
            <Popper
              onOpen={() => onOpen?.({ name: action.name })}
              handleOnClose={() => {
                onClose?.({ name: action.name });
                handleOnClose({ hasError: action.hasError, name: action.name });
              }}
              trigger={
                <motion.button
                  style={{ paddingInline: 0, paddingBlock: 0, color }}
                  key={action.name}
                  layout={disableLayout ? false : true}
                  initial={{ scale: 0 }}
                  animate={{
                    scale: [0, 1.1, 0.9, 1],
                    transition: { delay: 0.03 * (index + 1 * 0.17) }
                  }}
                  exit={{ scale: 0 }}
                  transition={{
                    width: { duration: 0.3 }
                  }}
                  type="button"
                >
                  {typeof action.icon === 'string' ? (
                    <span
                      data-name={action.name}
                      data-has-error={action.hasError}
                      style={{ color: action.hasValues ? color : undefined }}
                      className={clsx('material-icons', style.actionItem)}
                    >
                      {action.icon}
                    </span>
                  ) : (
                    <span
                      data-name={action.name}
                      data-has-error={action.hasError}
                      className={style.actionItem}
                      style={{ color: action.hasValues ? color : undefined }}
                    >
                      {action.icon}
                    </span>
                  )}
                </motion.button>
              }
            >
              {() => action.popover}
            </Popper>
          ) : (
            <motion.button
              className={style.button}
              key={action.name}
              style={{ paddingInline: 0, paddingBlock: 0, display: 'flex' }}
              layout={disableLayout ? false : true}
              initial={{ scale: 0 }}
              animate={{
                scale: [0, 1.1, 0.9, 1],
                transition: { delay: 0.03 * (index + 1 * 0.17) }
              }}
              exit={{ scale: 0 }}
              transition={{
                width: { duration: 0.3 }
              }}
              onClick={() => onActionClick?.({ type: action.name })}
              type="button"
              disabled={action.disabled}
            >
              {typeof action.icon === 'string' ? (
                <span
                  data-name={action.name}
                  data-has-error={action.hasError}
                  style={{ color: action.hasValues ? color : undefined }}
                  className={clsx('material-icons', style.actionItem)}
                >
                  {action.icon}
                </span>
              ) : (
                action.icon
              )}
            </motion.button>
          ))
      )}
    </Stack>
  );
};

export default Actions;
