import { Stack } from 'components';
import { useFormikContext } from 'formik';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';

import style from './colorPicker.module.scss';

const colors = ['#03A59D', '#15A0E0', '#4062D1', '#9252D1', '#C959C5', '#B1A110'];

type Props = {
  disabled?: boolean;
};

const ColorPicker = ({ disabled }: Props) => {
  const { values, setFieldValue } = useFormikContext<AddTodoFormValues>();

  return (
    <Stack display="flex" flexDirection="row" justifyContent="space-between" width="210px">
      {colors.map((color) => (
        <button
          ref={(ref) => {
            if (values.color === color && ref) {
              ref.focus();
            }
          }}
          className={style.colorPicker}
          key={color}
          data-selected={values.color === color}
          style={{ paddingBlock: 0, paddingInline: 0 }}
          onClick={() => !disabled && setFieldValue('color', color)}
        >
          <div className={style.color} style={{ backgroundColor: color }} />
        </button>
      ))}
    </Stack>
  );
};

export default ColorPicker;
