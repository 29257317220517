import {
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  MenuItem,
  PaperProps,
  Select,
  SelectChangeEvent,
  checkboxClasses
} from '@mui/material';
import clsx from 'clsx';
import { InputField } from 'components/inputField';
import { Stack } from 'components/stack';
import { Tooltip } from 'components/tooltip';
import {
  ChangeEvent,
  ComponentProps,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  FC
} from 'react';

import { Tag } from '../tag';
import style from './dropdown.module.scss';

interface DropdownData {
  id: number | string;
  name: string;
  category?: string;
  info?: string;
  tooltipPlacement?: string;
  tooltipDelay?: number;
}

type Type = 'default' | 'success' | 'warning';

type Size = 'normal' | 'medium' | 'small';

function removeDuplicates<T>(arr: T[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

function categorizeItems({
  array,
  categories,
  enableSorting
}: {
  array: DropdownData[];
  categories?: { id: string; label: string }[];
  enableSorting: boolean;
}) {
  const categorizedItems: {
    [key: string]: { id: string; name: string; children: DropdownData[] };
  } = {};
  const categorizedArray = [];

  array.forEach((item) => {
    if (item.category) {
      if (!categorizedItems[item.category]) {
        const found = categories?.find((category) => category.id === item.category);
        if (found) {
          categorizedItems[item.category] = {
            id: found.id,
            name: found?.label,
            children: []
          };
        }
      }
      categorizedItems[item.category].children?.push(item);
    } else {
      categorizedArray.push(item);
    }
  });

  const sortedCategorizedGroups = Object.values(categorizedItems).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  for (const group of sortedCategorizedGroups) {
    const sortedChildren = group.children;
    categorizedArray.push({ ...group, children: sortedChildren });
  }

  if (enableSorting) {
    categorizedArray.sort((a, b) => a.name.localeCompare(b.name));
  }

  return categorizedArray;
}

interface PropsTooltip {
  name: string;
  fitWidth?: boolean;
  hasValue: boolean;
}

const TruncatedText: FC<PropsTooltip> = ({ name, fitWidth, hasValue }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current;
      setIsTextTruncated(scrollWidth > offsetWidth);
    }
  }, [name]);

  return (
    <div ref={textRef} style={{ width: hasValue ? 'calc(100% - 24px)' : '100%' }}>
      {isTextTruncated ? (
        <Tooltip
          toolTipText={isTextTruncated ? name : ''}
          disableInteractive
          blackTooltip
          placement="bottom-start"
          enterDelay={300}
        >
          <ListItemText
            primary={name}
            sx={{
              '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }
            }}
          />
        </Tooltip>
      ) : (
        <ListItemText primary={name} />
      )}
    </div>
  );
};

interface DropdownProps {
  ref?: string;
  key?: string;
  id?: string;
  name?: string;
  data: readonly DropdownData[];
  label?: string;
  multiSelect?: boolean;
  value: string | string[];
  disabled?: boolean;
  rounded?: boolean;
  className?: string;
  sx?: ComponentProps<typeof Select>['sx'];
  type?: Type;
  size?: Size;
  onBlur?: ComponentProps<typeof Select>['onBlur'];
  onChange: (event: SelectChangeEvent<string | string[]>) => void;
  onFocus?: (event: ChangeEvent<HTMLSelectElement>) => void;
  categoryList?: { id: string; label: string }[];
  onInputBlur?: ComponentProps<typeof InputField>['onBlur'];
  onInputChange?: ComponentProps<typeof InputField>['onChange'];
  inputValue?: string;
  withSearch?: boolean;
  categorySelectable?: boolean;
  withGradient?: boolean;
  smallDropdown?: boolean;
  enableSorting?: boolean;
  withTooltip?: number;
  selectAll?: boolean;
  fitWidth?: boolean;
  open?: boolean;
  getOpen?: (e: any) => void;
  fitWidthMore?: number;
}
const Dropdown: FunctionComponent<DropdownProps> = ({
  name,
  label,
  data,
  onBlur,
  onChange,
  value,
  multiSelect = false,
  disabled = false,
  rounded = false,
  className,
  type = 'default',
  size = 'normal',
  sx,
  categoryList,
  onInputBlur,
  onInputChange,
  inputValue,
  withSearch = false,
  categorySelectable = false,
  withGradient = false,
  smallDropdown = false,
  enableSorting = false,
  withTooltip,
  selectAll = false,
  fitWidth = false,
  open = false,
  fitWidthMore = 0,
  getOpen
}) => {
  const [expanded, setExpanded] = useState<boolean>(open);
  const [searchInput, setSearchInput] = useState<string>('');
  const [autoFocus, setAutoFocus] = useState<boolean>(true);
  const [selectWidth, setSelectWidth] = useState<number>(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const paperRef = useRef<HTMLDivElement>(null);
  const uniqId = `dropdown-${Math.random().toString(36)}`.replace('.', '');

  const MenuProps: ComponentProps<typeof Select>['MenuProps'] = {
    PaperProps: {
      ref: paperRef,
      'data-test-id': 'dropdown-list',
      'data-uniq-id': uniqId,
      sx: {
        maxHeight: smallDropdown ? '300px' : '450px',
        width: fitWidth ? `${selectWidth + fitWidthMore}px` : 'auto',
        marginLeft: fitWidthMore > 0 ? '18px' : 'auto',
        '& .MuiList-root': {
          padding: '0px',
          boxShadow: '0px 2px 16px rgba(80, 117, 177, 0.1)',
          borderRadius: '8px'
        },
        '& .MuiListSubheader-root': {
          padding: '15px',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '140%',
          fontFamily: '"ProximaNova", sans-serif',
          color: '#314157',
          backgroundColor: '#F1F5F9'
        },
        '& .Mui-selected': {
          backgroundColor: '#EFF9FB !important',
          color: '#096F84'
        },
        '& .MuiMenuItem-root': {
          padding: '15px',
          backgroundColor: '#fff',
          '& .MuiTypography-root': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '22.4px',
            fontFamily: '"ProximaNova", sans-serif',
            color: '#314157'
          }
        },
        '& .MuiCheckbox-root': {
          padding: '0 11px 0 0'
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: '#F1F5F9'
        }
      }
    } as Partial<PaperProps> & { 'data-test-id': string },
    autoFocus
  };

  useEffect(() => {
    if (expanded) {
      setAutoFocus(false);
    } else {
      setAutoFocus(true);
    }
  }, [expanded]);

  const hasValue = Array.isArray(value) ? value?.length > 0 : value?.toString()?.length > 0;

  const mappedData = data.map((item) => {
    return {
      id: item.id?.toString(),
      name: item.name,
      category: item?.category
    };
  });

  const filteredData = data.filter((item) => {
    return item.name.toLowerCase().includes(inputValue?.toLowerCase() ?? searchInput.toLowerCase());
  });

  useEffect(() => {
    if (data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    if (data.length > 0) {
      setIsDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (isDataLoaded && !isEmpty && multiSelect && selectAll && Array.isArray(value)) {
      const validValues = value.filter((val) => data.some((item) => item.id.toString() === val));
      if (validValues.length !== value.length) {
        onChange({
          target: {
            value: validValues,
            name
          }
        } as SelectChangeEvent<string | string[]>);
      }
    }
  }, [data, value, multiSelect, onChange, name]);

  const handleSelectAll = () => {
    if (Array.isArray(value) && value.length === filteredData.length) {
      onChange({
        target: {
          value: [],
          name
        }
      } as SelectChangeEvent<string | string[] | never[]>);
    } else {
      const allItemIds = filteredData.map((item) => item.id.toString());
      onChange({
        target: {
          value: allItemIds,
          name
        }
      } as SelectChangeEvent<string | string[]>);
    }
  };

  const renderSelectAllOption = () => {
    if (
      !multiSelect ||
      !selectAll ||
      (filteredData && filteredData.length === 1) ||
      (filteredData && filteredData.length === 0)
    )
      return null;

    const allSelected = Array.isArray(value) && value.length === filteredData.length;
    return (
      <MenuItem
        key="select-all"
        onClick={handleSelectAll}
        sx={allSelected ? { backgroundColor: '#EFF9FB !important', color: '#096F84' } : null}
      >
        <Checkbox
          checked={allSelected}
          sx={{
            marginLeft: '0px',
            [`&, &.${checkboxClasses.root}`]: {
              color: '#8DA0B9'
            },
            [`&, &.${checkboxClasses.checked}`]: {
              color: '#096F84'
            }
          }}
        />
        <ListItemText primary="Select All" />
      </MenuItem>
    );
  };

  const renderTag = (v: string) => {
    const found = mappedData.find((a) => a.id === v);
    const categoryName = categoryList?.find((a) => a.id === found?.category)?.label;

    if (found && Array.isArray(value)) {
      return (
        <Tag
          blueSkin
          key={found.id}
          onDelete={() =>
            onChange({
              target: { value: value.filter((k) => v !== k), name }
            } as unknown as SelectChangeEvent<string>)
          }
          label={categoryName && categorySelectable ? `${categoryName}: ${found.name}` : found.name}
        />
      );
    }
  };

  const renderMenuitem = (item: DropdownData) => {
    const foundCategory = categoryList?.find((v) => item.id === v.id);
    const isCategory = Boolean(foundCategory);

    const categoryItems = mappedData.filter((obj) => obj.category === foundCategory?.id);

    const areAllCategoryItemsSelected = categoryItems.some((obj) => {
      return value?.includes?.(obj.id.toString());
    });

    const hasCategory = Boolean(item.category);

    return (
      <MenuItem
        key={item.id}
        value={item.id?.toString()}
        sx={
          isCategory && areAllCategoryItemsSelected
            ? { backgroundColor: '#EFF9FB !important', color: '#096F84' }
            : null
        }
      >
        {multiSelect && (
          <Checkbox
            checked={
              isCategory
                ? areAllCategoryItemsSelected
                : value.indexOf(item.id?.toString() ?? '') > -1
            }
            sx={{
              marginLeft: categorySelectable && !isCategory && hasCategory ? '32px' : '0px',
              [`&, &.${checkboxClasses.root}`]: {
                color: '#8DA0B9'
              },
              [`&, &.${checkboxClasses.checked}`]: {
                color: '#096F84'
              }
            }}
          />
        )}
        <Stack width="100%" display="flex" flexDirection="row" justifyContent="space-between">
          {item.info ? (
            <Tooltip
              placement={item.tooltipPlacement}
              toolTipText={item.info}
              disableInteractive
              enterDelay={item.tooltipDelay ?? 300}
              fullWidth
            >
              <ListItemText
                primary={item.name}
                sx={{
                  width: '100%',
                  '& span': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            </Tooltip>
          ) : (
            <TruncatedText
              name={item.name}
              fitWidth
              hasValue={hasValue && !multiSelect && value?.toString() === item.id?.toString()}
            />
          )}
          {hasValue && !multiSelect && value?.toString() === item.id?.toString() && (
            <div
              onClick={() =>
                onChange({
                  target: { value: '', name }
                } as unknown as SelectChangeEvent<string>)
              }
              className={clsx('material-icons-outlined', style.icon, style.iconClose)}
            >
              close
            </div>
          )}
        </Stack>
      </MenuItem>
    );
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) {
      onInputChange(event);
      return;
    }

    setSearchInput(event.target.value);
  };

  const sortValues = (val: string[] | string, sortOrder: DropdownData[]): string[] | string => {
    let sortedValues: string[] | string = [];

    if (Array.isArray(val)) {
      sortedValues = sortOrder
        .filter((item) => val.includes(item.id.toString()))
        .map((item) => item.id.toString());
    } else {
      sortedValues = val;
    }

    return sortedValues;
  };

  const handleChange = (e: SelectChangeEvent<string | string[]>) => {
    const selectedCategory = categoryList?.find((item) => e.target.value.includes(item.id));
    const isCategorySelected = Boolean(selectedCategory);

    if (
      isCategorySelected &&
      selectedCategory &&
      Array.isArray(e.target.value) &&
      categorySelectable
    ) {
      const categoryItems = mappedData.filter((obj) => obj.category === selectedCategory?.id);

      const areAllCategoryItemsSelected = categoryItems.every((obj) => {
        return e.target.value.includes(obj.id.toString());
      });

      // unselect all items under a group if all items are selected
      if (areAllCategoryItemsSelected) {
        const valuesWithoutCategoryItems = e.target.value
          .filter((k) => !categoryItems.find((obj) => obj.id.toString() === k))
          .filter((id) => selectedCategory.id !== id);
        onChange({
          ...e,
          target: {
            ...e.target,
            value: categoryList
              ? sortValues(valuesWithoutCategoryItems, filteredData)
              : valuesWithoutCategoryItems
          }
        } as unknown as SelectChangeEvent<string>);
        return;
      }

      const newValue = categoryItems.map((obj) => obj.id.toString());
      const valuesWithoutCategory = e.target.value.filter((id) => selectedCategory.id !== id);
      // select all items under a group
      onChange({
        ...e,
        target: {
          ...e.target,
          value: categoryList
            ? sortValues(removeDuplicates([...valuesWithoutCategory, ...newValue]), filteredData)
            : removeDuplicates([...valuesWithoutCategory, ...newValue])
        }
      } as unknown as SelectChangeEvent<string>);
    } else {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: categoryList ? sortValues(e.target.value, filteredData) : e.target.value
        }
      } as unknown as SelectChangeEvent<string>);
    }
  };

  // const resizeObserver = new ResizeObserver((entries) => {
  //   for (const entry of entries) {
  //     setSelectWidth(entry.contentBoxSize[0].inlineSize);
  //   }
  // });

  useEffect(() => {
    const inputElement = document.querySelector(`[data-uniq-id=${uniqId}]`);
    if (inputElement && fitWidth) {
      setSelectWidth(inputElement.getBoundingClientRect().width + fitWidthMore);
    }
  }, [fitWidth]);

  useEffect(() => {
    if (getOpen) {
      getOpen(expanded);
    }

    // setTimeout(() => {
    //   if (paperRef.current) {
    //     resizeObserver.observe(paperRef.current);
    //   }
    // }, 0);
    //
    // return () => {
    //   if (paperRef.current) {
    //     resizeObserver.unobserve(paperRef.current);
    //   }
    // };
  }, [expanded]);

  const categorizedItems = categorizeItems({
    array: filteredData,
    categories: categoryList,
    enableSorting
  });

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  return (
    <div className={style.inputContainer}>
      <FormControl fullWidth>
        <Select
          open={expanded}
          onOpen={() => setExpanded(true)}
          onClose={() => setExpanded(false)}
          IconComponent={() => (
            <>
              <span
                data-expanded={expanded}
                className={clsx(
                  'material-icons',
                  style.icon,
                  disabled && style.iconDisabled,
                  size === 'small' && style.smallIcon,
                  type === 'success' && style.iconSuccess,
                  type === 'warning' && style.iconWarning
                )}
              >
                expand_more
              </span>
            </>
          )}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          disabled={disabled}
          multiple={multiSelect}
          sx={{
            position: 'relative',
            ...sx
          }}
          data-selected={hasValue}
          data-rounded={rounded}
          className={clsx(
            style.select,
            rounded && style.rounded,
            disabled && style.disabled,
            rounded && disabled && style.roundedDisabled,
            size === 'small' && style.small,
            size === 'medium' && style.medium,
            size === 'normal' && style.normal,
            type === 'success' && style.success,
            type === 'warning' && style.warning,
            withGradient && style.gradient,
            className
          )}
          displayEmpty
          renderValue={(selected) => {
            if (multiSelect && Array.isArray(selected)) {
              if (selected.length === 0) {
                return <span>{label}</span>;
              }
              const newArr = selected.map((item: string) => {
                if (mappedData.find((a) => a.id === item.toString())) {
                  const found = mappedData.find((a) => a.id === item.toString());
                  return found?.name;
                }
              });

              return <span>{newArr.join(', ')}</span>;
            }
            if (withTooltip && selected) {
              const found = mappedData.find((a) => a.id === selected.toString())?.name;
              if (found && found.length > withTooltip) {
                return (
                  <span>
                    <Tooltip
                      toolTipText={mappedData.find((a) => a.id === selected.toString())?.name}
                      blackTooltip
                      styles={{
                        marginBottom: '0 !important'
                      }}
                      placement="top-start"
                    >
                      <span
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          display: 'block'
                        }}
                      >
                        {mappedData.find((a) => a.id === selected.toString())?.name}
                      </span>
                    </Tooltip>
                  </span>
                );
              }
            }
            if (selected) {
              return <span>{mappedData.find((a) => a.id === selected.toString())?.name}</span>;
            }
            return <span>{label}</span>;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={MenuProps}
          data-test-id="dropdown-list"
          data-uniq-id={uniqId}
        >
          {withSearch && (
            <ListSubheader
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              sx={{
                padding: '0px !important'
              }}
            >
              <InputField
                classnamesProps={style.search}
                placeholder="Search"
                onBlur={onInputBlur}
                onChange={handleSearchInputChange}
                value={inputValue ?? searchInput}
                tags={<span className="material-icons">search</span>}
              />
            </ListSubheader>
          )}
          {multiSelect && value.length > 0 && (
            <div
              className={style.tags}
              data-test-id="selected-tags"
              style={{ width: selectWidth ? selectWidth + fitWidthMore : 'min-content' }}
            >
              {Array.isArray(value) &&
                value.length === filteredData.length &&
                multiSelect &&
                selectAll &&
                filteredData &&
                filteredData.length > 1 && (
                  <Tag
                    blueSkin
                    key="select-all"
                    onDelete={() =>
                      onChange({
                        target: { value: [], name }
                      } as unknown as SelectChangeEvent<string>)
                    }
                    label="Select-all"
                  />
                )}
              {Array.isArray(value) && value.map((v) => renderTag(v))}
            </div>
          )}
          {renderSelectAllOption()}
          {categorizedItems.map((item) => {
            if (item.children) {
              const items = [item, ...item.children];
              return items.map((listItem) => {
                if (!categorySelectable && 'children' in listItem) {
                  return (
                    <ListSubheader key={listItem.id} disableSticky>
                      {listItem.name}
                    </ListSubheader>
                  );
                }
                return renderMenuitem(listItem);
              });
            }
            return renderMenuitem(item);
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
