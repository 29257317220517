import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { LoadingSpinner, Stack } from 'components';
import useMultiKeyPress from 'utils/useMultiKeyPress';
import { useLockBodyScroll } from 'utils/useLockBodyScroll';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { flushSync } from 'react-dom';
import { useEffect, useState } from 'react';
import { getNewTodoDraft, getSearchQueryDraft, getTodos } from 'store/myTodos/myTodosActions';

import style from './myTodos.module.scss';
import { AddTodoForm } from './addTodoForm';
import { Filters } from './filters';
import { Header } from './header';
import { DayList } from './dayList';
import { ClientList } from './clientList';
import { SearchList } from './searchList';

const scrollToElementById = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      block: 'start'
    });
  }
};

const MyTodos = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { view } = useAppSelector((state) => state.myTodos);
  const showSearch = useAppSelector((state) => state.myTodos.search.show);
  const [open, setOpen] = useState(false);
  const toggleSidebar = (i: boolean) => {
    // we want to update this state synchronously so that we can scroll to the correct section after the sidebar is opened
    flushSync(() => {
      setOpen(i);
    });

    if (view === 'dayView') {
      scrollToElementById('Overdue');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(getTodos()),
        dispatch(getNewTodoDraft()),
        dispatch(getSearchQueryDraft())
      ]);
      setLoading(false);
    };
    fetchData();
  }, []);

  useLockBodyScroll({ lock: open });

  useMultiKeyPress(() => {
    toggleSidebar(true);
  }, ['Control', 't']);

  useMultiKeyPress(() => {
    toggleSidebar(false);
  }, ['Escape']);

  return (
    <>
      <div className={style.wrapper}>
        <AnimatePresence initial={false} mode="wait">
          {!open ? (
            <motion.button
              key="closed"
              layout
              className={clsx(style.button)}
              onClick={() => toggleSidebar(true)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1, bounce: 0 }}
            >
              <span className="material-icons">task</span>
            </motion.button>
          ) : (
            <>
              <motion.aside
                key="open"
                layout
                initial={{ width: 0 }}
                animate={{ width: 350 }}
                exit={{ width: 0 }}
                style={{ height: '100vh', position: 'relative', zIndex: 92 }}
                transition={{ duration: 0.3, bounce: 0 }}
              >
                <div className={style.sidebar}>
                  {loading ? (
                    <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                      <LoadingSpinner />
                    </Stack>
                  ) : (
                    <Stack display="flex" flexDirection="column" gap="20px" height="100%">
                      <Stack
                        display="flex"
                        flexDirection="column"
                        gap="20px"
                        paddingX="20px"
                        flexShrink={1}
                      >
                        <Header toggleSidebar={() => toggleSidebar(false)} />
                        <Filters />
                        <AnimatePresence initial={false}>
                          {!showSearch && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              exit={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                            >
                              <AddTodoForm />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Stack>
                      <AnimatePresence initial={false}>
                        {showSearch ? (
                          <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                              overflowX: 'hidden',
                              position: 'relative',
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <SearchList />
                          </motion.div>
                        ) : (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                              overflowX: 'hidden',
                              position: 'relative',
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <AnimatePresence initial={false} mode="popLayout">
                              {view === 'dayView' && (
                                <motion.div
                                  key={view}
                                  layout
                                  initial={{ opacity: 0, x: 250 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  exit={{
                                    opacity: 0,
                                    x: 250
                                  }}
                                  transition={{ duration: 0.3, type: 'spring', bounce: 0 }}
                                  style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                                >
                                  <DayList />
                                </motion.div>
                              )}
                              {view === 'clientView' && (
                                <motion.div
                                  layout
                                  key={view}
                                  transition={{ duration: 0.3, type: 'spring', bounce: 0 }}
                                  initial={{ opacity: 0, x: -250 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  exit={{
                                    opacity: 0,
                                    x: -250
                                  }}
                                  style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                                >
                                  <ClientList />
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Stack>
                  )}
                </div>
              </motion.aside>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={style.backdrop}
                onClick={() => toggleSidebar(false)}
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MyTodos;
