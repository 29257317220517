import { Stack } from 'components';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
import { useAppDispatch } from 'store/hooks';
import { setView, Todo as TodoType } from 'store/myTodos/myTodosSlice';
import {
  useFilteredTodosByStatus,
  useNoClientTodos,
  useWithClientTodos
} from 'store/myTodos/selectors';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';

import style from './clientList.module.scss';
import { Todo } from '../components/Todo';
import { NO_CLIENT_TITLE } from '../utils/sectionUtils';
import ClientListSection from './clientListSection';

const haveADraft = ({ todo, userId }: { todo: TodoType; userId: any }) =>
  Boolean(todo.draft) &&
  todo.draft?.userId === userId &&
  todo.draft?.creationContext === 'MY_TODOS';

const ClientList = () => {
  const dispatch = useAppDispatch();
  const { userId } = getLsUserRoleObject();

  const noClientTodos = useNoClientTodos();
  const withClientTodos = useWithClientTodos();
  const canSeeClientTodos = useIsOnlyVisibleToUserWithPermissions([PERMISSION.VIEW_CLIENT_TODOS]);
  const filteredTodos = useFilteredTodosByStatus();

  if (!canSeeClientTodos) {
    dispatch(setView('dayView'));
  }

  if (filteredTodos.length === 0) {
    return (
      <Stack alignItems="center" flexGrow={1} justifyContent="center">
        <p className={style.emptyListText}>Nothing to see here!</p>
      </Stack>
    );
  }

  return (
    <Stack
      flexGrow={1}
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}
      marginBottom="20px"
      boxSizing="border-box"
    >
      <Stack display="flex" gap="10" minHeight={0}>
        {noClientTodos.length > 0 && (
          <ClientListSection
            title={NO_CLIENT_TITLE}
            expanded={noClientTodos.some((todo) => Boolean(todo.draft))}
            disabled={
              noClientTodos.some((todo) => haveADraft({ todo, userId })) ||
              noClientTodos.some((todo) => todo.isAutosaving)
            }
          >
            {noClientTodos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </ClientListSection>
        )}
        {withClientTodos.map((item) => (
          <ClientListSection
            key={item.clientId}
            title={item.clientName}
            expanded={item.todos.some((todo) => Boolean(todo.draft))}
            disabled={
              item.todos.some((todo) => haveADraft({ todo, userId })) ||
              item.todos.some((todo) => todo.isAutosaving)
            }
          >
            {item.todos.map((todo) => (
              <Todo key={todo.id} data={todo} />
            ))}
          </ClientListSection>
        ))}
      </Stack>
    </Stack>
  );
};

export default ClientList;
