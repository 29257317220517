import { Dropdown, Stack } from 'components';
import { useFormikContext } from 'formik';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';

type Goal = {
  id: number | string;
  title: string;
};

type Props = {
  disabled?: boolean;
  clientId?: number;
  goals: Goal[];
};

const GoalPicker = ({ disabled, goals = [] }: Props) => {
  const { values, handleChange, handleBlur } = useFormikContext<AddTodoFormValues>();

  return (
    <Stack width="220px">
      <Dropdown
        name="goalId"
        value={values.goalId?.toString() || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        data={goals.map((goal) => ({
          id: goal.id,
          name: goal.title
        }))}
        label="Select goal"
        disabled={disabled}
      />
    </Stack>
  );
};

export default GoalPicker;
