import { useEffect, useRef } from 'react';

function useMultiKeyPress(callback: () => void, targetKeys: string[]) {
  const keysPressed = useRef(new Set());

  useEffect(() => {
    const downHandler = ({ key }: { key: string }) => {
      keysPressed.current.add(key);

      // Check if all target keys are pressed
      if (targetKeys.every((k) => keysPressed.current.has(k))) {
        callback();
      }
    };

    const upHandler = ({ key }: { key: string }) => {
      keysPressed.current.delete(key);
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [callback, targetKeys]);

  return keysPressed.current;
}

export default useMultiKeyPress;
