import {
  CLIENTS_SECTION_URL,
  ENROLLMENT_PLANNER_SECTION_URL,
  ORGANIZATIONS_SECTION_URL,
  REPORTING_SECTION_URL
} from 'constants/utils';

import { Avatar } from '@mui/material';
import clsx from 'clsx';
import { Logo, NavBar } from 'components';
import { HelpButton, ProfilePopper } from 'modules';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserInfo, getUserInfoImage } from 'store/profile/profileActions';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';

import { getLsUserRoleObject } from '../networkTools/localStorageTokens';
import style from './header.module.scss';

export const showPinnedHeader = (location: any) => {
  let showPinned = false;
  const { pathname } = location;
  showPinned = pathname.includes('ourWork');
  return showPinned;
};

interface Props {}

const Header: FC<Props> = (props) => {
  const anchorRef = useRef(null);
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile.info);
  const profileImage = useAppSelector((state) => state.profile.profileImage);
  const userRoleData = getLsUserRoleObject();
  const isHelpButtonView = useIsOnlyVisibleToUserWithPermissions([PERMISSION.VIEW_HELP_BUTTON]);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const navItems = [
    // {
    //   path: MY_DAY_SECTION_URL,
    //   name: 'My Day',
    //   permission: [PERMISSION.MY_DAY_READ],
    //   icon: 'home'
    // },
    {
      showItem: 'true',
      path: ORGANIZATIONS_SECTION_URL,
      name: 'Organizations',
      permission: [PERMISSION.ORG_READ],
      icon: 'domain'
    },
    // {
    //   path: HIRING_SECTION_URL,
    //   name: 'Hiring',
    //   permission: [PERMISSION.HIRING_READ],
    //   icon: 'business_center'
    // },
    // {
    //   path: ASK_MARY_SECTION_URL,
    //   name: 'Ask Mary',
    //   permission: [PERMISSION.ASK_MARY_READ],
    //   icon: 'face_3'
    // },
    {
      showItem: `true`,
      path: ENROLLMENT_PLANNER_SECTION_URL,
      name: 'Enrollment',
      permission: [PERMISSION.ENROLL_READ],
      icon: 'groups_2'
    },
    {
      showItem: 'true',
      path: CLIENTS_SECTION_URL,
      name: 'Clients',
      permission: [PERMISSION.CLIENTS_READ],
      icon: 'diversity_4'
    },
    {
      showItem: 'true',
      path: REPORTING_SECTION_URL,
      name: 'Reporting',
      permission: [PERMISSION.CLIENTS_READ],
      icon: 'bar_chart'
    }
  ];

  useEffect(() => {
    dispatch(getUserInfo(userRoleData?.organizationId));
  }, [userRoleData?.organizationId]);

  useEffect(() => {
    if (profile?.profileImageKey) {
      dispatch(getUserInfoImage(profile.profileImageKey));
    }
  }, [profile?.profileImageKey]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(style.headerContainer)}>
      <div className={style.header}>
        <div className={style.logo}>
          <Logo type="small" />
        </div>
        <NavBar navItems={navItems}></NavBar>

        <div className={style.wrap}>
          {isHelpButtonView && <HelpButton />}
          <>
            <div
              ref={anchorRef}
              onClick={handleClick}
              className={clsx(style.info, style.wrap)}
              data-test-id="profile-modal-btn"
            >
              <div className={style.title}>
                <Avatar className={style.avatar} src={profileImage}>
                  <span className="material-icons-outlined">person</span>
                </Avatar>
              </div>
              <span className={clsx('material-icons', style.icon, open && style.activeIcon)}>
                expand_more
              </span>
            </div>
            <ProfilePopper open={open} anchorEl={anchorRef.current} onClose={onClose} />
          </>
        </div>
      </div>
    </div>
  );
};

export default Header;
