import dayjs from 'dayjs';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().max(120, 'Title cannot be longer than 120 characters.'),
  date: yup
    .string()
    .nullable()
    .when('hasDifferentEndDate', {
      is: true,
      then: (schema) => schema.required('Start Date is required.')
    })
    .test('requiredDate', 'Date is required.', (date, context) => {
      if (
        (context.parent.startTime != null && context.parent.startTime !== '') ||
        (context.parent.endTime != null && context.parent.endTime !== '')
      ) {
        return date != null && date !== '';
      }
      return true;
    }),
  endDate: yup
    .string()
    .nullable()
    .when('hasDifferentEndDate', {
      is: true,
      then: (schema) => schema.required('End Date is required.')
    })
    .test('endDateBeforeStartDate', 'End Time should be after Start Time.', (endDate, context) => {
      if (!context.parent.hasDifferentEndDate || endDate == null) {
        return true;
      }
      const startDate = context.parent.date;

      return dayjs(endDate).isSameOrAfter(startDate, 'day');
    }),
  startTime: yup
    .string()
    .nullable()
    .test('requiredStartTimeTest', 'Start Time is required.', (fieldVal, context) => {
      if (context.parent.hasDifferentEndDate && context.parent.endDate && fieldVal == null) {
        return false;
      }

      if (context.parent.endTime == null || context.parent.endTime === '') {
        return true;
      }

      if (fieldVal != null && fieldVal !== '') {
        return true;
      }

      return false;
    }),
  endTime: yup
    .string()
    .nullable()
    .test('requiredEndTimeTest', 'End Time is required.', (fieldVal, context) => {
      if (context.parent.hasDifferentEndDate && context.parent.endDate && fieldVal == null) {
        return false;
      }

      if (context.parent.startTime == null || context.parent.startTime === '') {
        return true;
      }

      if (fieldVal != null && fieldVal !== '') {
        return true;
      }

      return false;
    })
    .test('endTimeTest', 'End Time should be after Start Time.', (fieldVal, context) => {
      const startTime = context.parent.startTime;
      const startDate = context.parent.date;
      const endDate = context.parent.hasDifferentEndDate ? context.parent.endDate : startDate;

      if (startTime == null || startTime === '' || fieldVal == null || fieldVal === '') {
        return true;
      }

      const startTimeDate = dayjs(
        startDate ? `${startDate}T${startTime}` : `2022-01-01T${startTime}`
      );
      const endTimeDate = dayjs(endDate ? `${endDate}T${fieldVal}` : `2022-01-01T${fieldVal}`);

      return startTimeDate.isBefore(endTimeDate);
    })
});
