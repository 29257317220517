import { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
};

const ClientButton = ({ style = {} }: Props) => {
  return (
    <svg
      style={{ ...style, fill: 'currentColor' }}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M12 7.7998C12.4167 7.7998 12.7708 7.65814 13.0625 7.3748C13.3542 7.09147 13.5 6.73314 13.5 6.2998C13.5 5.88314 13.3542 5.52897 13.0625 5.2373C12.7708 4.94564 12.4167 4.7998 12 4.7998C11.5667 4.7998 11.2083 4.94564 10.925 5.2373C10.6417 5.52897 10.5 5.88314 10.5 6.2998C10.5 6.73314 10.6417 7.09147 10.925 7.3748C11.2083 7.65814 11.5667 7.7998 12 7.7998ZM12 9.7998C11.0167 9.7998 10.1875 9.4623 9.5125 8.7873C8.8375 8.1123 8.5 7.28314 8.5 6.2998C8.5 5.33314 8.8375 4.50814 9.5125 3.8248C10.1875 3.14147 11.0167 2.7998 12 2.7998C12.9667 2.7998 13.7917 3.14147 14.475 3.8248C15.1583 4.50814 15.5 5.33314 15.5 6.2998C15.5 7.28314 15.1583 8.1123 14.475 8.7873C13.7917 9.4623 12.9667 9.7998 12 9.7998ZM8 14.1748C8 14.6748 8.26667 15.2581 8.8 15.9248C9.33333 16.5915 10.4 17.6498 12 19.0998C13.5667 17.6831 14.625 16.6415 15.175 15.9748C15.725 15.3081 16 14.7081 16 14.1748C16 13.7915 15.875 13.4665 15.625 13.1998C15.375 12.9331 15.0667 12.7998 14.7 12.7998C14.4667 12.7998 14.2458 12.8498 14.0375 12.9498C13.8292 13.0498 13.6583 13.1915 13.525 13.3748L12.5424 14.5416C12.4046 14.7053 12.2015 14.7998 11.9875 14.7998C11.7735 14.7998 11.5704 14.7053 11.4326 14.5416L10.45 13.3748C10.3167 13.1915 10.1458 13.0498 9.9375 12.9498C9.72917 12.8498 9.51667 12.7998 9.3 12.7998C8.91667 12.7998 8.60417 12.9331 8.3625 13.1998C8.12083 13.4665 8 13.7915 8 14.1748ZM6 14.1748C6 13.2915 6.3 12.5081 6.9 11.8248C7.5 11.1415 8.3 10.7998 9.3 10.7998C9.81667 10.7998 10.3125 10.9165 10.7875 11.1498C11.2625 11.3831 11.6667 11.6998 12 12.0998C12.3333 11.6998 12.7333 11.3831 13.2 11.1498C13.6667 10.9165 14.1667 10.7998 14.7 10.7998C15.7 10.7998 16.5 11.1456 17.1 11.8373C17.7 12.529 18 13.3081 18 14.1748C18 15.0581 17.6792 15.929 17.0375 16.7873C16.3958 17.6456 15.0833 18.9831 13.1 20.7998L12.6727 21.1883C12.2913 21.535 11.7087 21.535 11.3273 21.1883L10.9 20.7998C8.88333 18.9665 7.5625 17.6248 6.9375 16.7748C6.3125 15.9248 6 15.0581 6 14.1748Z" />
    </svg>
  );
};

export default ClientButton;
