export enum ROLE {
  IMPACT_PERFORMANCE_ANALYST = 'IMPaCT Performance Analyst',
  IMPACT_HIRING_MANAGER = 'IMPaCT Hiring Manager',
  SUPERVISOR_DIRECTOR = 'Supervisor (Director)',
  SUPERVISOR_MANAGER = 'Supervisor (Manager)',
  IMPACT_CHW_TRAINER = 'IMPaCT CHW Trainer',
  IMPACT_INTERVIEWER = 'IMPaCT Interviewer',
  SYSTEM_ADMIN = 'System Administrator',
  IMPACT_SCREENER = 'IMPaCT Screener',
  IMPACT_LEADER = 'IMPaCT Leader',
  COORDINATOR = 'Coordinator',
  CHW_TRAINEE = 'CHW Trainee',
  CHW = 'CHW',
  UNMARK_CLIENT_FOR_REVIEW = 'unmark_client_for_review'
}
export enum PERMISSION {
  //HOW TO USE PERMISSIONS
  // -------------------------------
  // All permissions must exist on BE and be linkd with role
  // if this is not a case, FE dev must contact BE dev to add new or existing permission to some role
  // for example IMPACT_INTERVIEWER must have organization_delete permission
  // -------------------------------
  //HOW TO USE IT IN COMPONENT
  // FIND useIsOnlyVisibleToUserWithPermissions([PERMISSION.USER_ADMINISTRATION]);
  // AND IMPLEMENT LIKE THAT
  // -------------------------------
  ORG_READ = 'organization_read',
  ORG_DELETE = 'organization_delete',
  ORG_CHANGE_STATUS = 'organization_change_status',
  PROGRAM_STATUS_MODIFICATION = 'program_status_modification',
  USER_ADMINISTRATION = 'user_administration',
  VIEW_USER_ADMINISTRATION = 'view_user_administration',
  USER_DELETION = 'user_deletion',
  ENROLL_READ = 'enrollment_read',
  ENROLL_WRITE = 'enrollment_write',
  ENROLL_SET_AND_EDIT_GOALS = 'enrollment_planner_set_edit_goals',

  MY_DAY_READ = 'my_day_read',
  PERFORMANCE_READ = 'performance_read',
  CLIENTS_READ = 'clients_read',
  ASK_MARY_READ = 'ask_mary_read',
  HIRING_READ = 'hiring_read',
  UPDATE_GOAL_STATUS = 'update_goal_status',
  VIEW_HELP_BUTTON = 'view_help_button',
  UPDATE_MANAGER_REVIEW = 'update_manager_review',
  VIEW_CLIENT_TODOS = 'view_client_todos',
  VIEW_MY_TODOS = 'view_my_todos'
}
