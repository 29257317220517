import { MenuItem, Popper } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MaterialTimePicker } from '@mui/x-date-pickers/TimePicker';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

import style from './timePicker.module.scss';

type TimePickerProps = {
  classnamesProps?: any;
  disabled?: boolean;
  value: any;
  onBlur?: any;
  onChange: any;
  onFocus?: any;
  disablePast?: boolean;
  placeholder?: string;
  name?: string | null;
  showIcon?: boolean;
};

const timeOptions = Array.from({ length: 24 * 4 }).map((_, index) => {
  const time = dayjs()
    .startOf('day')
    .add(15 * index, 'minutes');

  return time;
});

const TimePicker = ({
  value = null,
  onChange,
  onBlur,
  classnamesProps,
  showIcon = true,
  disabled
}: TimePickerProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const menuItemRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});
  const popperRef = useRef<HTMLDivElement | null>(null);
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      anchorRef.current &&
      !anchorRef.current.contains(event.target as Node) &&
      event.target instanceof HTMLElement &&
      !event.target.classList.contains('MuiMenuItem-root') &&
      !event.target.classList.contains('MuiPopperUnstyled-root')
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOnChangeLocal = (time: dayjs.Dayjs | string) => {
    if (time == null || time === 'Invalid Date') {
      onChange(null);
      return;
    }
    onChange(dayjs(time ?? null).format('HH:mm:ss'));
  };

  const val = value && value !== '' ? dayjs(`2022-01-01T${value}`) : null;

  const onOpen = () => {
    if (open) {
      return;
    }

    // we want this state update to run synchronously so we can scroll to the selected time after the menu has opened
    flushSync(() => {
      setOpen(true);
    });

    const selectedTime = menuItemRefs.current[val?.format('hh:mm A') ?? '08:00 AM'];

    if (selectedTime) {
      popperRef.current?.scrollTo({
        top: selectedTime.offsetTop,
        behavior: 'auto'
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={clsx(style.datePicker, classnamesProps, disabled && style.disabled)}
        onClick={onOpen}
        ref={anchorRef}
      >
        {showIcon && <span className="material-icons">access_time</span>}
        <MaterialTimePicker
          className={style.timePickerMUi}
          sx={{
            '& input': {
              padding: '0px'
            }
          }}
          views={['hours', 'minutes']}
          minutesStep={5}
          format="hh:mm A"
          defaultValue={val}
          onChange={(x: any) => {
            handleOnChangeLocal(x?.$d);
            onBlur?.();
          }}
          value={val}
          onAccept={onBlur ?? undefined}
          slots={{
            openPickerIcon: () => null,
            openPickerButton: () => null
          }}
          slotProps={{ textField: { placeholder: '00:00 AM', onBlur } }}
          disabled={disabled}
        />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{ width: anchorRef.current ? anchorRef.current.clientWidth : 'auto' }}
        className={style.timeOptions}
        ref={popperRef}
      >
        {timeOptions.map((time) => {
          const isHighlighted = time.format('hh:mm A') === val?.format('hh:mm A');
          return (
            <MenuItem
              key={time.format('hh:mm A')}
              ref={(menuItemRef) => {
                menuItemRefs.current[time.format('hh:mm A')] = menuItemRef;
              }}
              className={clsx(isHighlighted ? style.highlighted : '')}
              onClick={() => {
                handleOnChangeLocal(time);
                setOpen(false);
                onBlur?.();
              }}
            >
              {time.format('hh:mm A')}
            </MenuItem>
          );
        })}
      </Popper>
    </LocalizationProvider>
  );
};

export default TimePicker;
