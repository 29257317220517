import { Stack } from 'components';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { searchTodos } from 'store/myTodos/myTodosActions';
import { clearSearchData } from 'store/myTodos/myTodosSlice';
import debounce from 'lodash.debounce';
import { AnimatePresence, motion } from 'framer-motion';

import style from './searchList.module.scss';
import { Todo } from '../components/Todo';

const SearchList = () => {
  const { data, query } = useAppSelector((state) => state.myTodos.search);
  const dispatch = useAppDispatch();

  const onSearchTodos = async (q: string) => {
    dispatch(searchTodos({ query: q }));
  };

  const debounceSearch = useCallback(debounce(onSearchTodos, 500), []);

  useEffect(() => {
    if (query === '') {
      dispatch(clearSearchData());

      return;
    }

    debounceSearch(query);
  }, [query]);

  if (!data || query.trim() === '') return null;

  if (data?.length === 0) {
    return (
      <Stack
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX="40px"
      >
        <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <p className={style.emptyMessage}>
            No matches for <span>“{query}”</span> were found in your to-dos.
          </p>
        </motion.div>
      </Stack>
    );
  }

  return (
    <Stack display="flex" gap="2px" minHeight={0} flexGrow={1}>
      <AnimatePresence mode="popLayout">
        {data.map((todo) => (
          <motion.div
            key={todo.id}
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2, type: 'spring', bounce: 0 }}
          >
            <Todo data={todo} exitAnimation={false} />
          </motion.div>
        ))}
      </AnimatePresence>
    </Stack>
  );
};

export default SearchList;
