import { Auth0ProviderWithNavigate } from 'modules/oauth/Auth0ProviderWithNavigate';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import App from './App';
import store from './store';

(async () => {
  const launchDarklyClientKey = String(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientKey
    // context: {
    //   kind: 'user',
    //   key: 'unknown profile'
    // }
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <BrowserRouter>
      <LDProvider>
        <Auth0ProviderWithNavigate>
          <Provider store={store}>
            <App />
          </Provider>
        </Auth0ProviderWithNavigate>
      </LDProvider>
    </BrowserRouter>
  );
})();
