import dayjs from 'dayjs';
import { useAppSelector } from 'store/hooks';
import { Todo } from 'store/myTodos/myTodosSlice';

type PastSection = {
  date: string | null;
  todos: Todo[];
};

type ClientSections = {
  clientId: number;
  clientName: string;
  todos: Todo[];
};

export const useFilteredTodosByStatus = () => {
  const status = useAppSelector((state) => state.myTodos.filters.status);
  const todos = useAppSelector((state) => state.myTodos.todos.data);

  return status.length > 0 ? todos.filter((todo) => status.includes(todo.status)) : todos;
};

function groupTodosByDate(todos: Todo[]): PastSection[] {
  // Use reduce to group todos by date
  const groupedTodos = todos.reduce((acc, todo) => {
    // Handle todos with no dates

    let date: string | null = null;

    if (todo.startDateTime) {
      // Get date string (YYYY-MM-DD) from startDateTime
      date = dayjs(todo.startDateTime).format('YYYY-MM-DD');
    } else if (todo.date) {
      console.log({ todo });
      date = todo.date;
    }

    // Find existing section or create new one
    const existingSection = acc.find((section) => section.date === date);

    if (existingSection) {
      existingSection.todos.push(todo);
    } else {
      acc.push({
        date: date,
        todos: [todo]
      });
    }

    return acc;
  }, [] as PastSection[]);

  // sort todos by startDateTime
  groupedTodos.forEach((section) => {
    section.todos.sort(
      (a, b) =>
        new Date(a.startDateTime as string).getTime() -
        new Date(b.startDateTime as string).getTime()
    );
  });

  // Sort sections by date
  const sortedSections = groupedTodos.sort(
    (a, b) => new Date(a.date as string).getTime() - new Date(b.date as string).getTime()
  );
  return sortedSections;
}

const sortTodosByUpdatetStatusAt = (todos: Todo[]) => {
  return todos.sort(
    (a, b) =>
      new Date(b.statusUpdatedAt ?? '').getTime() - new Date(a.statusUpdatedAt ?? '').getTime()
  );
};

const sortTodosByOrderNum = (todos: Todo[]) => {
  return todos.sort((a, b) => (a.orderNum as number) - (b.orderNum as number));
};

function divideTodosByStatus(todos: Todo[], hasClient: boolean): Todo[] {
  const activeTodos = todos.filter((todo) => todo.status === 'ACTIVE');
  const completedTodos = todos.filter((todo) => todo.status === 'COMPLETED');
  const abandonedTodos = todos.filter((todo) => todo.status === 'ABANDONED');

  if (!hasClient) {
    return [
      ...sortTodosByUpdatetStatusAt(activeTodos),
      ...sortTodosByUpdatetStatusAt(completedTodos),
      ...sortTodosByUpdatetStatusAt(abandonedTodos)
    ];
  }

  return [
    ...sortTodosByOrderNum(activeTodos),
    ...sortTodosByUpdatetStatusAt(completedTodos),
    ...sortTodosByUpdatetStatusAt(abandonedTodos)
  ];
}

const sortSectionsByName = (sections: ClientSections[]) => {
  return sections.sort((a, b) => a.clientName.localeCompare(b.clientName));
};

function sortTodosByClient(todos: Todo[]): ClientSections[] {
  // Group todos by client
  const clientMap = todos.reduce<Record<number, ClientSections>>((acc, todo) => {
    const clientId = todo.client?.id;
    const clientName = todo.client?.name;

    if (clientId == null || clientName == null) {
      return acc;
    }

    if (!acc[clientId]) {
      acc[clientId] = {
        clientId,
        clientName,
        todos: []
      };
    }

    acc[clientId].todos.push(todo);

    return acc;
  }, {});

  // Convert the object map to an array and sort todos within each client section
  return Object.values(clientMap).map((section) => {
    section.todos = divideTodosByStatus(section.todos, true);
    return section;
  });
}

export const isInactive = (todo: Todo) => {
  return todo.status !== 'ACTIVE';
};

export const isActive = (todo: Todo) => {
  return todo.status === 'ACTIVE';
};

export const isInPast = (todo: Todo) => {
  if (todo.date) {
    return dayjs(todo.date).isBefore(dayjs(), 'days');
  }
  return dayjs(todo.startDateTime).isBefore(dayjs(), 'minutes');
};

export const isInFuture = (todo: Todo) => {
  if (todo.date) {
    return dayjs(todo.date).isSameOrAfter(dayjs(), 'days');
  }

  return (
    dayjs(todo.startDateTime).isAfter(dayjs(), 'minutes') ||
    (dayjs().isAfter(todo.startDateTime, 'minutes') &&
      dayjs().isBefore(todo.endDateTime, 'minutes'))
  );
};

const hasClient = (todo: Todo) => {
  return Boolean(todo.client);
};

export const isOverdue = (todo: Todo) => {
  if (todo.date) {
    return dayjs(todo.date).isBefore(dayjs(), 'days') && isActive(todo);
  }
  return dayjs(todo.endDateTime).isBefore(dayjs(), 'minutes') && isActive(todo);
};
// Hooks
export const usePastTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const pastTodos = groupTodosByDate(
    filteredTodosByStatus.filter((todo) => isInPast(todo) && isInactive(todo) && !isOverdue(todo))
  );

  return pastTodos;
};

export const useNoDateTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const noDateTodos = filteredTodosByStatus.filter((todo) => !todo.startDateTime && !todo.date);

  return noDateTodos.sort(
    (a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime()
  );
};

export const useOverdueTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const overdueTodos = filteredTodosByStatus.filter((todo) => isOverdue(todo));

  return overdueTodos.sort(
    (a, b) =>
      new Date(a.startDateTime || (a.date as string)).getTime() -
      new Date(b.startDateTime || (b.date as string)).getTime()
  );
};

export const useFutureTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const futureTodos = groupTodosByDate(filteredTodosByStatus.filter((todo) => isInFuture(todo)));

  return futureTodos;
};

export const useNoClientTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const noClientTodos = divideTodosByStatus(
    filteredTodosByStatus.filter((item) => !hasClient(item)),
    false
  );

  return noClientTodos;
};

export const useWithClientTodos = () => {
  const filteredTodosByStatus = useFilteredTodosByStatus();

  const withClientTodos = sortSectionsByName(
    sortTodosByClient(filteredTodosByStatus.filter((item) => hasClient(item)))
  );

  return withClientTodos;
};
