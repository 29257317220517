import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';
import { ChangeEvent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { searchClients } from 'store/myTodos/myTodosActions';
import { clearClients } from 'store/myTodos/myTodosSlice';

import style from './clientSearch.module.scss';

type Props = {
  disabled?: boolean;
  onChange?: (value: { id: number; name: string }) => void;
};

function ClientSearch({ disabled, onChange }: Props) {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.myTodos.clients.data);
  const { values, setFieldValue } = useFormikContext<AddTodoFormValues>();

  const onSearch = (term: string) => {
    dispatch(searchClients(term));
  };

  const debounceSearch = useCallback(debounce(onSearch, 400), []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      dispatch(clearClients());
    } else {
      debounceSearch(e.target.value);
    }
  };

  const onClientChange = (value: { id: number; name: string }) => {
    setFieldValue('client', value);
    setFieldValue('goalId', null);
    dispatch(clearClients());
    onChange?.(value);
  };

  return (
    <Combobox value={values.client} onChange={onClientChange} immediate>
      <ComboboxInput
        className={style.input}
        aria-label="Client"
        placeholder="Search client"
        displayValue={(client: { id: number; name: string }) => client?.name}
        onChange={onInputChange}
        autoFocus
        disabled={disabled}
      />
      <ComboboxOptions anchor="bottom" style={{ zIndex: 9999 }} className={style.optionsWrapper}>
        {clients.map((client) => (
          <ComboboxOption key={client.id} value={client} className={style.option}>
            {client.name}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  );
}

export default ClientSearch;
