import dayjs from 'dayjs';

export const NO_DATE_TITLE = 'No date';
export const OVERDUE_TITLE = 'Overdue';

export const NO_CLIENT_TITLE = 'No client';

export const getDayListSectionTitle = (date: string) => {
  return dayjs(date).format('MMM D, dddd');
};
