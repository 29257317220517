import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';

type CommonTodo = {
  goalId: number | null;
  title: string | null;
  status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED';
  date?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  location: string | null;
  note: string | null;
  color: string;
  clientId?: number | null;
};

type DraftTodo = CommonTodo & { id?: number; creationContext: 'MY_TODOS' };

export const getTodos = createAsyncThunk('my-todos/getTodos', async (_, { rejectWithValue }) => {
  const searchParams = new URLSearchParams({ size: '50', page: '0' });
  try {
    const response = await axiosPrivate.get(`client/my-todos?${searchParams.toString()}`);

    const total = response.data.totalNumber;

    let content = response.data.content;

    if (total > 50) {
      const promises = [];
      for (let i = 50; i < total; i += 50) {
        searchParams.set('page', (i / 50).toString());
        promises.push(axiosPrivate.get(`client/my-todos?${searchParams.toString()}`));
      }

      const results = await Promise.all(promises);
      content = [...response.data.content, ...results.flatMap((result) => result.data.content)];
    }

    return { content };
  } catch (error: any | AxiosError) {
    return rejectWithValue(error.message);
  }
});

export const postCreateTodo = createAsyncThunk(
  'my-todos/postCreateTodo',
  async (data: CommonTodo & { draftId?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`client/todo`, {
        ...data,
        creationContext: 'MY_TODOS'
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putNewTodoDraft = createAsyncThunk(
  'my-todos/putNewTodoDraft',
  async (data: DraftTodo & { draftId?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/new-todo-draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNewTodoDraft = createAsyncThunk(
  'my-todos/getNewTodoDraft',
  async (_, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({ creationContext: 'MY_TODOS' });
    try {
      const response = await axiosPrivate.get(`client/new-todo-draft?${searchParams.toString()}`);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteNewTodoDraft = createAsyncThunk(
  'my-todos/deleteNewTodoDraft',
  async ({ draftId }: { draftId: number }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({
      creationContext: 'MY_TODOS',
      draftId: draftId.toString()
    });
    try {
      await axiosPrivate.delete(`client/new-todo-draft?${searchParams.toString()}`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchClients = createAsyncThunk(
  'my-todos/searchClients',
  async (term: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`client/clients-search?term=${term}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getGoals = createAsyncThunk(
  'my-todos/getGoals',
  async ({ clientId }: { clientId: number; todoId?: number }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({ statuses: 'ACTIVE' });

    try {
      const response = await axiosPrivate.get(
        `client/${clientId}/goals?${searchParams.toString()}`
      );
      console.log('response', response);
      return response.data;
    } catch (error: any | AxiosError) {
      console.log('error', error);
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSearchQueryDraft = createAsyncThunk(
  'my-todos/getSearchQueryDraft',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('client/search-query-draft');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSearchQueryDraft = createAsyncThunk(
  'my-todos/deleteSearchQueryDraft',
  async (_, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete('client/search-query-draft');
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putSearchQueryDraft = createAsyncThunk(
  'my-todos/putSearchQueryDraft',
  async ({ searchQuery }: { searchQuery: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('client/search-query-draft', { searchQuery });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchTodos = createAsyncThunk(
  'my-todos/searchTodos',
  async ({ query }: { query: string }, { rejectWithValue, signal }) => {
    try {
      const response = await axiosPrivate.get(`client/todo/search?term=${query}`, { signal });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateTodoDraft = createAsyncThunk(
  'my-todos/putUpdateTodoDraft',
  async (data: DraftTodo, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/todo/${data.id}/draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTodoDraft = createAsyncThunk(
  'my-todos/deleteTodoDraft',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`client/todo/${id}/draft`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchUpdateTodoStatus = createAsyncThunk(
  'my-todos/patchUpdateTodoStatus',
  async (
    data: { id: number; status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED' },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`client/todo/status`, data);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateTodo = createAsyncThunk(
  'my-todos/putUpdateTodo',
  async (data: CommonTodo & { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/todo`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
