import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';

import { Status } from './roadmapsSlice';

export const getTodos = createAsyncThunk(
  'roadmaps/getTodos',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`client/${clientId}/todo`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTodoOrder = createAsyncThunk(
  'roadmaps/updateTodoOrder',
  async (
    { clientId, todos }: { clientId: string; todos: { id: number; orderNum: number }[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`client/${clientId}/todo/order`, todos);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Todo = {
  title: string;
  date: string | null;
  startDateTime: string | null;
  endDateTime: string | null;
  location: string | null;
  note: string | null;
  color: string;
  goalId: number | null;
  status: Status;
};

export const postCreateTodo = createAsyncThunk(
  'roadmaps/postCreateTodo',
  async (
    {
      todo,
      clientId
    }: {
      todo: Todo & { draftId?: number | null; creationContext: 'ROADMAPS' | 'GOALS' };
      clientId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(`client/todo`, {
        ...todo,
        clientId: Number(clientId),
        creationContext: 'ROADMAPS'
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type TodoWithId = Todo & { id: number };

export const putUpdateTodo = createAsyncThunk(
  'roadmaps/putUpdateTodo',
  async (todo: TodoWithId & { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/todo`, todo);
      console.log('Response updating todo', response);
      return response.data;
    } catch (error: any | AxiosError) {
      console.log('Error updating todo', error);
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchUpdateTodoStatus = createAsyncThunk(
  'roadmaps/patchUpdateTodoStatus',
  async (
    data: { id: number; status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED' },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`client/todo/status`, data);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTodoDraft = createAsyncThunk(
  'roadmaps/deleteTodoDraft',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`client/todo/${id}/draft`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getGoals = createAsyncThunk(
  'roadmaps/getGoals',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`client/${clientId}/goals`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGoalOrder = createAsyncThunk(
  'roadmaps/updateGoalOrder',
  async (
    { clientId, goals }: { clientId: string; goals: { id: number | string; orderNum: number }[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`client/${clientId}/goals/order`, goals);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type DraftTodo = Todo & { id?: number; creationContext: 'ROADMAPS' | 'GOALS'; clientId: number };

export const putNewTodoDraft = createAsyncThunk(
  'roadmaps/putNewTodoDraft',
  async (data: DraftTodo & { draftId?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/new-todo-draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNewTodoDraft = createAsyncThunk(
  'roadmaps/getNewTodoDraft',
  async (
    { clientId, creationContext }: { clientId: string; creationContext: 'ROADMAPS' | 'GOALS' },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ creationContext });
    searchParams.append('clientId', clientId);
    try {
      const response = await axiosPrivate.get(`client/new-todo-draft?${searchParams.toString()}`);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteNewTodoDraft = createAsyncThunk(
  'roadmaps/deleteNewTodoDraft',
  async (
    {
      clientId,
      goalId,
      draftId,
      creationContext
    }: {
      clientId: string;
      goalId?: number | null;
      draftId?: number;
      creationContext: 'ROADMAPS' | 'GOALS';
    },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ creationContext, clientId });
    if (goalId) {
      searchParams.append('goalId', goalId.toString());
    }

    if (draftId) {
      searchParams.append('draftId', draftId.toString());
    }

    try {
      await axiosPrivate.delete(`client/new-todo-draft?${searchParams.toString()}`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export type NewGoalValues = {
  title: string;
  date: string | null;
};

export const postCreateGoal = createAsyncThunk(
  'roadmaps/postCreateGoal',
  async (data: NewGoalValues & { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`client/${data.clientId}/goals`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateGoal = createAsyncThunk(
  'roadmaps/putUpdateGoal',
  async (data: NewGoalValues & { clientId: string; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/${data.clientId}/goals`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putNewGoalDraft = createAsyncThunk(
  'roadmaps/putNewGoalDraft',
  async (data: NewGoalValues & { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/${data.clientId}/goals/new-goal-draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteNewGoalDraft = createAsyncThunk(
  'roadmaps/deleteNewGoalDraft',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`client/${clientId}/goals/new-goal-draft`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchChangeGoalStatus = createAsyncThunk(
  'roadmaps/patchChangeGoalStatus',
  async ({
    id,
    status,
    clientId
  }: {
    id: number;
    status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED';
    clientId: string;
  }) => {
    const response = await axiosPrivate.patch(`client/${clientId}/goals/status`, {
      id,
      status
    });
    return response.data;
  }
);

export const putUpdateTodoDraft = createAsyncThunk(
  'roadmaps/putUpdateTodoDraft',
  async (data: DraftTodo, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/todo/${data.id}/draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNewGoalDraft = createAsyncThunk(
  'roadmaps/getNewGoalDraft',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`client/${clientId}/goals/new-goal-draft`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putGoalDraft = createAsyncThunk(
  'roadmaps/putGoalDraft',
  async (data: NewGoalValues & { clientId: string; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/${data.clientId}/goals/draft`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteGoalDraft = createAsyncThunk(
  'roadmaps/deleteGoalDraft',
  async ({ clientId, id }: { clientId: string; id: number }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({ goalId: id.toString() });
    try {
      await axiosPrivate.delete(`client/${clientId}/goals/draft?${searchParams.toString()}`);
      return;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
