import clsx from 'clsx';
import { ComponentProps } from 'react';

import reactivateButtonStyle from './reactivateButton.module.scss';

const ReactivateButton = ({ style = {}, ...props }: ComponentProps<'button'>) => {
  return (
    <span
      style={{ ...style, paddingBlock: '0px', paddingInline: '0px' }}
      className={clsx('material-icons', reactivateButtonStyle.icon)}
    >
      replay_circle_filled
    </span>
  );
};

export default ReactivateButton;
