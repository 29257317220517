import { LabelComponent, Stack } from 'components';
import { TextAreaInputField } from 'components/inputField/textAreaInpuField';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { useAnimate } from 'framer-motion';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';

type Props = {
  disabled?: boolean;
};

const NotesForm = ({ disabled }: Props) => {
  const { values, handleChange, handleBlur } = useFormikContext<AddTodoFormValues>();
  const [scope, animate] = useAnimate();

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const clippedSnapshot = e.target.value.slice(0, 250);

    handleChange({
      ...e,
      target: { ...e.target, value: clippedSnapshot, name: 'note' }
    });

    if (e.target.value.length <= 249) {
      return;
    }
    animate(
      'div[data-error="true"]',
      {
        x: [-3, 3, -3, 3, 0]
      },
      { duration: 0.2, ease: 'easeIn' }
    );
  };

  return (
    <Stack ref={scope} width="220px">
      <LabelComponent text="Notes" />
      <TextAreaInputField
        disabled={disabled}
        placeholder="Type here"
        name="note"
        onBlur={handleBlur}
        onChange={onChange}
        value={values.note ?? ''}
        rowsNumber={4}
        maxLength={250}
        enableCounter
      />
    </Stack>
  );
};

export default NotesForm;
