import clsx from 'clsx';
import { ChangeEvent, ComponentProps, FC } from 'react';

import style from './standardCheckbox.module.scss';

interface StandardCheckboxProps {
  ref?: string;
  key?: string;
  id?: string;
  name?: string;
  label?: string | null | JSX.Element;
  value: boolean | undefined;
  customClass?: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: ComponentProps<'input'>['onClick'];
  disabled?: boolean;
  readOnly?: boolean;
}

const StandardCheckbox: FC<StandardCheckboxProps> = ({
  ref,
  key,
  id,
  name,
  label,
  value = false,
  onBlur,
  onChange,
  onFocus,
  customClass,
  disabled,
  onClick,
  readOnly
}) => {
  return (
    <div className={clsx(style.inputContainer, customClass)}>
      <input
        disabled={disabled}
        ref={ref}
        key={key}
        id={id}
        checked={value}
        name={name}
        type={'checkbox'}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        readOnly={readOnly}
      />

      <div className={style.checkBoxLabel}>{label}</div>
    </div>
  );
};

export default StandardCheckbox;
