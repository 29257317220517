import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getGoals, putNewTodoDraft } from 'store/myTodos/myTodosActions';
import debounce from 'lodash.debounce';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import {
  clearClients,
  clearGoals,
  setHasOpenedPopper,
  toggleClientViewSection,
  toggleDayViewSection
} from 'store/myTodos/myTodosSlice';
import dayjs from 'dayjs';

import { Actions } from '../components/actions';
import { ClientSearch } from '../components/actions/clientSearch';
import ColorPicker from '../components/actions/colorPicker/colorPicker';
import EventForm from '../components/actions/eventForm/eventForm';
import { GoalPicker } from '../components/actions/goalPicker';
import { LocationForm } from '../components/actions/locationForm';
import { NotesForm } from '../components/actions/notesForm';
import { AddTodoFormValues } from './addTodoForm';
import actionsStyle from '../components/actions/actions.module.scss';
import { mapDates } from '../utils/date';
import { ClientButton } from '../components/clientButton';

const scrollToElementById = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
};

const useAutosaveForm = () => {
  const dispatch = useAppDispatch();
  const { values, dirty } = useFormikContext<AddTodoFormValues>();
  const newTodoDraft = useAppSelector((state) => state.myTodos.newTodoDraft);

  const autosaveForm = async (newValues: AddTodoFormValues) => {
    const { date, startDateTime, endDateTime } = mapDates({
      date: newValues.date,
      endDate: newValues.endDate,
      startTime: newValues.startTime,
      endTime: newValues.endTime,
      hasDifferentEndDate: newValues.hasDifferentEndDate
    });

    dispatch(
      putNewTodoDraft({
        id: newValues.id ?? undefined,
        title: newValues.title,
        clientId: newValues.client?.id,
        goalId: newValues.goalId,
        note: newValues.note,
        location: newValues.location,
        color: newValues.color,
        startDateTime,
        endDateTime,
        date,
        status: 'ACTIVE',
        creationContext: 'MY_TODOS',
        draftId: newTodoDraft?.id ?? undefined
      })
    );
  };

  const debounceAutosave = useCallback(debounce(autosaveForm, 400), []);

  useEffect(() => {
    if (dirty) {
      debounceAutosave(values);
    }
  }, [values]);
};

const AddTodoFormActions = () => {
  const dispatch = useAppDispatch();
  const { values, errors } = useFormikContext<AddTodoFormValues>();
  const goals = useAppSelector((state) => state.myTodos.goals.data);
  const canSeeClientTodos = useIsOnlyVisibleToUserWithPermissions([PERMISSION.VIEW_CLIENT_TODOS]);
  useAutosaveForm();

  const onClientChange = (value: { id: number; name: string }) => {
    if (value.name !== null && value.name !== '') {
      dispatch(toggleClientViewSection({ section: value.name, expanded: true }));
      scrollToElementById(value.name);
    }
  };

  const onDateChange = (date: string) => {
    if (date !== null && date !== '') {
      const title = dayjs(date).format('MMM D, dddd');
      dispatch(toggleDayViewSection({ section: title, expanded: true }));
      scrollToElementById(title);
    }
  };

  const onOpen = ({ name }: { name: string }) => {
    dispatch(setHasOpenedPopper({ opened: true }));
  };

  const onClose = ({ name }: { name: string }) => {
    if (name === 'client') {
      dispatch(clearClients());
    }
    dispatch(setHasOpenedPopper({ opened: false }));
  };

  useEffect(() => {
    if (values.client?.id) {
      dispatch(getGoals({ clientId: values.client.id }));
    } else {
      dispatch(clearGoals());
    }
  }, [values.client]);

  const actions = [
    {
      name: 'client',
      icon: <ClientButton />,
      popover: <ClientSearch onChange={onClientChange} />,
      show: canSeeClientTodos,
      hasValues: values.client != null
    },
    {
      name: 'goal',
      icon: 'landscape',
      popover: <GoalPicker goals={goals} />,
      show: values.client != null,
      hasValues: values.goalId != null
    },
    {
      name: 'event',
      icon: 'event',
      popover: <EventForm onDateChange={onDateChange} />,
      hasValues: [values.date, values.endDate, values.startTime, values.endTime].some(
        (value) => value != null && value !== ''
      ),
      hasError: [errors.date, errors.endDate, errors.startTime, errors.endTime].some(
        (value) => value != null
      ),
      show: true
    },
    {
      name: 'notes',
      icon: 'notes',
      popover: <NotesForm />,
      hasValues: values.note != null && values.note !== '',
      show: true
    },
    {
      name: 'location',
      icon: 'location_on',
      popover: <LocationForm />,
      hasValues: values.location != null && values.location !== '',
      show: true
    },
    {
      name: 'color',
      icon: (
        <div className={actionsStyle.colorPicker}>
          <div className={actionsStyle.color} style={{ backgroundColor: values.color }} />
        </div>
      ),
      popover: <ColorPicker />,
      show: true
    }
  ];

  return <Actions actions={actions} color={values.color} onOpen={onOpen} onClose={onClose} />;
};

export default AddTodoFormActions;
