import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';

type HealthTrackerItem = {
  metricId?: number | string | null;
  entryDate: string | null;
  entryTime: string | null;
  value: number | null;
  source: string;
};

export const getHealthTracker = createAsyncThunk(
  'healthTracker/getHealthTracker',
  async (
    {
      clientId,
      metricId,
      page,
      size,
      sorts
    }: {
      clientId: string | number;
      metricId: string | number;
      page?: number;
      size?: number;
      sorts?: { title: string; direction: string }[] | null;
    },
    { rejectWithValue }
  ) => {
    try {
      let sortArray: string | string[] = '';
      if (sorts && sorts.length > 0) {
        sortArray = sorts
          .map((item) => item.direction && `&sorts=${item.title}:${item.direction}`)
          .join('');
      }

      const response = await axiosPrivate.get(
        `client/${clientId}/health-tracker/entry?metricId=${metricId}&page=${page}&size=${size}${sortArray}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postHealthTracker = createAsyncThunk(
  'healthTracker/postHealthTracker',
  async (
    { clientId, data }: { clientId: string | number; data: HealthTrackerItem },
    { rejectWithValue }
  ) => {
    try {
      await axiosPrivate.post(`client/${clientId}/health-tracker/entry`, data);
      return data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putHealthTracker = createAsyncThunk(
  'healthTracker/putHealthTracker',
  async (
    {
      clientId,
      entryId,
      data
    }: { clientId: string | number; entryId: string | number; data: HealthTrackerItem | any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(
        `client/${clientId}/health-tracker/entry/${entryId}`,
        data
      );
      return { data: response.data, entryId };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteHealthTracker = createAsyncThunk(
  'healthTracker/deleteHealthTracker',
  async (
    {
      clientId,
      entryId
    }: {
      clientId: string | number;
      entryId: string | number;
    },
    { rejectWithValue }
  ) => {
    try {
      await axiosPrivate.delete(`client/${clientId}/health-tracker/entry/${entryId}`);

      return entryId;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getHealthTrackerCategory = createAsyncThunk(
  'healthTracker/getHealthTrackerCategory',
  async ({ clientId }: { clientId: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`client/${clientId}/health-tracker/metric`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type HealthTrackerCategoryItemPost = {
  name: string | null;
  direction: string | null;
};

type CombinedHealthTrackerData = HealthTrackerCategoryItemPost & HealthTrackerItem;

export const postHealthTrackerCategory = createAsyncThunk(
  'healthTracker/postHealthTrackerCategory',
  async (
    { clientId, data }: { clientId: string | number; data: CombinedHealthTrackerData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(`client/${clientId}/health-tracker/metric`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchHealthTrackerCategory = createAsyncThunk(
  'healthTracker/patchHealthTrackerCategory',
  async (
    {
      clientId,
      metricId,
      type
    }: {
      clientId: string | number;
      metricId: string | number;
      type: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await axiosPrivate.patch(
        `client/${clientId}/health-tracker/metric/${metricId}?primary=${type}`
      );
      return { metricId, type };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
